import { faChevronLeft } from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons/faInfoCircle";
import { faLock } from "@fortawesome/free-solid-svg-icons/faLock";
import { faArrowUpRightFromSquare } from "@fortawesome/pro-duotone-svg-icons/faArrowUpRightFromSquare";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { addDays } from "date-fns/addDays";
import { format } from "date-fns/format";
import { isSameDay } from "date-fns/isSameDay";
import { subDays } from "date-fns/subDays";
import { useCallback, useState } from "react";

import DatePickerCalendar from "components/Common/DatePicker/DatePickerCalendar";
import BasicTooltip from "components/Common/Tooltip/BasicTooltip";
import ReachPopUp from "pages/PodcastView/Insights/Sections/insightReach/ReachPopUp";

import modalActions from "actions/modals";
import sendGAEvent from "utils/sendGAEvent";

import useActionCreators from "hooks/useActionCreators";
import useHover from "hooks/useHover";
import useOutsideClickHandler from "hooks/useOutsideClickHandler";
import { useStyles } from "hooks/useStyles";
import useUserHasPro from "hooks/useUserHasPro";
import useWindowSize from "hooks/useWindowSize";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  outer: {
    width: "fit-content",
    position: "relative",
    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "center",
    marginBottom: "1.5rem",
  },
  container: {
    position: "relative",
    maxWidth: 220,
  },
  input: {
    ...gStyles.avalonBold,
    backgroundColor: colours.white,
    border: "1px solid rgba(0,0,0,0.1)",
    position: "relative",
    padding: 0,
    margin: "0",
    display: "grid",
    gridTemplateColumns: "40px 1fr 40px",
    justifyItems: "center",
    alignItems: "center",
    justifyContent: "space-around",
    width: "100%",
    fontSize: "0.875rem",
    height: "2.5rem",
    color: colours.buttonText,
    outline: "none",
    textAlign: "left",
    borderRadius: "4em",
    lineHeight: 1,
    cursor: "pointer",
    ":focus": {
      outline: "none",
      borderColor: "rgba(0,0,0,0.25)",
    },
    ":active": {
      outline: "none",
    },
    maxWidth: 220,
  },
  buttons: {
    border: "none",
    cursor: "pointer",
  },
  disabled: {
    display: "none",
  },
  calendar: {
    position: "absolute",
    zIndex: "999",
    background: "white",
    border: "1px solid rgba(0,0,0,0.1)",
    top: "3rem",
  },
  lockIcon: {
    ...gStyles.avalonBold,
    position: "absolute",
    border: `0.5px solid ${colours.darkishBlue}`,
    color: colours.selectedDarkBlue,
    background: colours.selectedLightBlue,
    borderRadius: "25px",
    width: "100%",
    textAlign: "Center",
    top: 0,
    height: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "0.5rem",

    ":hover": {
      cursor: "pointer",
    },
  },
  linkBlack: {
    ...gStyles.fontBold,
    fontSize: "0.813rem",
    color: colours.black,
    textAlign: "center",
    margin: "0",

    ":hover": {
      cursor: "pointer",
    },
  },

  para: {
    ...gStyles.fontRegular,
    fontSize: "0.813rem",
    color: colours.black,
    textAlign: "center",
    margin: 0,
  },
  paraBold: {
    ...gStyles.avalonBold,
    fontSize: "0.813rem",
    color: colours.black,
    textAlign: "center",
    margin: 0,
    border: "none",
  },

  link: {
    ...gStyles.fontBold,
    fontSize: "0.813rem",
    color: colours.primary,
    textAlign: "center",
    margin: "0 0 0 0.188rem",
    border: "none",
    ":hover": {
      cursor: "pointer",
    },
  },
  infoIcon: {
    marginLeft: "0.5rem",
    fontSize: "1.25rem",
    color: "var(--color-neutral-d9)",
    paddingTop: 1,

    ":hover": {
      cursor: "pointer",
    },
    position: "relative",
  },
  infoIconActive: {
    color: "var(--color-neutral-d2)",
  },
  popUpTitle: {
    ...gStyles.avalonBold,
    fontSize: "1.25rem",
    color: colours.black,
    textAlign: "center",
    margin: 0,
  },
  icons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
};

const customModifiersStyles = {
  selected: {
    color: colours.white,
    backgroundColor: colours.darkishBlue,
    fontWeight: "bold",
  },
};

const proDateStyles = {
  container: {
    gridTemplateRows: "1fr",
  },
  outer: {
    marginLeft: 0,
    marginTop: 0,

    [ScreenSizes.mdAndAbove]: {
      marginTop: 0,
      marginLeft: 0,
      left: "-1.5rem",
      top: "2.25rem",
    },
  },
  triangle: {
    right: "unset",
    left: "auto",
    transform: "rotate(134deg)",
    top: "-34px",
  },
};

export default function ChartsDateSelect(props) {
  const { fromDate, toDate, onChange, selectedDate } = props;

  const [showCalendar, setShowCalendar] = useState(false);
  const [showInfo, setShowInfo] = useState(false);

  const { styles, css } = useStyles(baseStyles, props);

  const wrapperRef = useOutsideClickHandler(() => setShowCalendar(false));
  const wrapperInfoRef = useOutsideClickHandler(() => setShowInfo(false));

  const hasPro = useUserHasPro();
  const [hoverRef, isHovered] = useHover();

  const { isWindowSizeOrLess } = useWindowSize();
  const onMobile = isWindowSizeOrLess("medium");

  const onInfoClick = useCallback(() => {
    if (!showInfo) {
      sendGAEvent({
        action: "infoIconClick",
        context: "Browse Charts Filters",
        page: "Browse Charts",
        type: "chartDate",
      });
    }
    return setShowInfo(!showInfo);
  }, [showInfo]);

  const handlePreviousDay = (event) => {
    event.stopPropagation();
    onChange(subDays(selectedDate, 1));
  };

  const handleNextDay = (event) => {
    event.stopPropagation();
    onChange(addDays(selectedDate, 1));
  };

  const handleToggleCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  const handleCalendarChange = (date) => {
    onChange(date);
  };

  const { showModal } = useActionCreators({
    showModal: modalActions.showModal,
  });

  const onDemoRequestSubmitted = useCallback(() => {
    sendGAEvent({
      entity_type: "chart",
      context: "ProLeadForm",
      page: "Browse Charts List",
    });
  }, []);

  const handleCTA = useCallback(() => {
    sendGAEvent({
      action: "unlockDateWithProButtonClicked",
      entity_type: "chart",
      context: "Browse Charts",
      page: "Charts Browse Page",
    });

    showModal("proLeadForm", {
      onDemoRequestSubmitted,
    });
  }, [onDemoRequestSubmitted, showModal]);

  const today = new Date();
  const isToday = selectedDate && isSameDay(selectedDate, today);

  const [currentMonth, setCurrentMonth] = useState(selectedDate || null);

  return (
    <div className={css(styles.outer)} ref={hoverRef}>
      <div className={css(styles.icons)}>
        <div
          className={css(styles.infoIcon, showInfo && styles.infoIconActive)}
        >
          {showInfo && (
            <ReachPopUp passedRef={wrapperInfoRef} styles={proDateStyles}>
              <h2 className={css(styles.popUpTitle)}>Date</h2>
              <p className={css(styles.para)} ref={wrapperInfoRef}>
                For historical charts beyond 90 days,&nbsp;
                <a
                  href="mailto:sales@podchaser.com"
                  target="_blank"
                  rel="noreferrer"
                  className={css(styles.linkBlack)}
                >
                  contact our sales team
                </a>
                &nbsp; about our API and custom data reports.
              </p>
              {!hasPro && (
                <div>
                  {onMobile ? (
                    <button
                      className={css(styles.paraBold)}
                      onClick={handleCTA}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Learn more &nbsp;&nbsp;
                      <FontAwesomeIcon
                        icon={faArrowUpRightFromSquare}
                        dataid="expand"
                      />
                    </button>
                  ) : (
                    <p className={css(styles.para)}>
                      Learn more about
                      <button
                        className={css(styles.link)}
                        onClick={handleCTA}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Podchaser Pro
                      </button>
                    </p>
                  )}
                </div>
              )}
            </ReachPopUp>
          )}
          <span onClick={onInfoClick}>
            <FontAwesomeIcon icon={faInfoCircle} data-testid="infoCircle" />
          </span>
        </div>
      </div>

      <div className={css(styles.container)} ref={wrapperRef}>
        <div className={css(styles.input)} onClick={handleToggleCalendar}>
          <button
            data-testid="previous"
            className={css(styles.buttons)}
            onClick={handlePreviousDay}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
          <span>{selectedDate && format(selectedDate, "MMMM d, yyyy")}</span>
          <button
            data-testid="next"
            className={css(styles.buttons, isToday && styles.disabled)}
            onClick={handleNextDay}
            disabled={isToday}
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>
        <div className={css(styles.calendar)}>
          {showCalendar && (
            <DatePickerCalendar
              captionLayout="dropdown-buttons"
              mode="single"
              selected={selectedDate}
              setSelected={handleCalendarChange}
              fromDate={format(fromDate, "yyyy-MM-dd")}
              toDate={format(toDate, "yyyy-MM-dd")}
              customModifiersStylesProp={customModifiersStyles}
              month={currentMonth}
              onMonthChange={setCurrentMonth}
            />
          )}
        </div>
        {!hasPro && isHovered && (
          <BasicTooltip renderTooltip={() => "Unlock with Pro"}>
            {(tooltipProps) => (
              <span
                {...tooltipProps}
                className={css(styles.lockIcon)}
                onClick={handleCTA}
                data-testid="UnlockPro"
              >
                <FontAwesomeIcon icon={faLock} dataid="lock" />
                Unlock With Pro
              </span>
            )}
          </BasicTooltip>
        )}
      </div>
    </div>
  );
}
