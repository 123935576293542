import { getTime } from "date-fns/getTime";
import { parseISO } from "date-fns/parseISO";
import { fromJS } from "immutable";

export function getUserMetaImageUrl(user) {
  return `https://imagegen.podchaser.com/user/${user.get("username")}.png`;
}

export function getCacheBustingSuffix(user) {
  const indexedAt = user.get("indexed_at");
  let parsedDate;
  if (indexedAt !== undefined) {
    parsedDate = parseISO(indexedAt);
  } else {
    parsedDate = new Date();
  }

  return getTime(parsedDate);
}

export function getTop8ImageUrl(user) {
  return `https://imagegen.podchaser.com/top8/${user.get(
    "username"
  )}.png?t=${getCacheBustingSuffix(user)}`;
}

export function getUserBadgeImageUrl(user, badgeEarned) {
  if (badgeEarned) {
    return `https://imagegen.podchaser.com/user_badge/${user.get(
      "username"
    )}/${badgeEarned}.png`;
  }

  return null;
}

export function userHasPermission(passedUser, permission) {
  let user = passedUser;

  if (user && !user.get) {
    user = fromJS(user);
  }

  return (
    user &&
    user.get &&
    user.get("permissions") &&
    user.get("permissions").includes(permission)
  );
}

export function userHasProPermission(user) {
  return userHasPermission(user, VIEW_PRO_PERMISSION);
}
export function userHasModeratorPermission(user) {
  return userHasPermission(user, HAS_MODERATOR_PERMISSION);
}
export function userHasInternalPermission(user) {
  return userHasPermission(user, INTERNAL_PERMISSION);
}
export function userHasPoliticalSkewPermission(user) {
  return userHasPermission(user, VIEW_POLITICAL_SKEW_PERMISSION);
}
export function userHasViewBrandSafetyPermission(user) {
  return userHasPermission(user, BRAND_SAFETY_PERMISSION);
}

export const CAN_MODERATE_PERMISSION = "approve creators";
export const INTERNAL_PERMISSION = "view internal only";
export const BRAND_SAFETY_PERMISSION = "view web brand safety";
export const HAS_MODERATOR_PERMISSION = "moderate feeds";
export const VIEW_SPONSOR_HISTORY_PERMISSION = "view sponsor history";
export const VIEW_BRAND_CONTACTS_PERMISSION = "view brand contacts";
export const VIEW_PRO_PERMISSION = "view pro data";
export const VIEW_RATE_CARD_PERMISSION = "view rate card";
export const VIEW_POLITICAL_SKEW_PERMISSION = "view political lean";
