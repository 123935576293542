import { faCaretDown } from "@fortawesome/free-solid-svg-icons/faCaretDown";
import { faLoader } from "@fortawesome/pro-duotone-svg-icons/faLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Downshift from "downshift";
import { fromJS, Map } from "immutable";
import PropTypes from "prop-types";
import { useCallback, useMemo, useState } from "react";

import { baseStyles as selectStyles } from "components/Lists/SelectStyles";
import { hasSubOption } from "pages/Discover/Charts/Charts";

import MenuItemWithSubMenu, {
  compareFunc,
} from "../../Lists/FilterSelect/Filters/components/MenuItemWithSubMenu";

import generateTransition from "utils/generateTransition";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";

const baseStyles = {
  outer: {
    width: "100%",
    position: "relative",
    margin: 0,
    fontSize: "inherit",
  },
  input: {
    ...gStyles.headerFilterButton,
    height: "2rem",
    width: "max-content",
  },
  content: {
    ...gStyles.headerFilterButtonContent,
    flex: "1 1 100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0.7em 1rem",
  },
  text: {
    ...gStyles.headerFilterButtonLabel,
    flex: "1 1 auto",
    paddingRight: "0.5em",
    fontSize: ".875rem",
  },
  sideButtons: {
    display: "flex",
    flex: "0 0 auto",
    flexDirection: "row",
    ...selectStyles.sideButtons,
    height: "auto",
  },
  sideButtonLink: {
    padding: "0.7em 0.7em 0.6em",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    ":hover": {
      background: colours.lightGreyishBlue,
    },
    ":focus": {
      background: colours.lightGreyishBlue,
    },
  },
  toggleIcon: {
    maxWidth: "1em",
    display: "block",
    flex: "0 0 auto",
    alignSelf: "center",
    justifySelf: "center",
    height: "auto",
    transition: generateTransition({
      targets: ["opacity", "transform"],
      speed: "400ms",
    }),
    transformOrigin: "50%",
  },
  options: {
    ...gStyles.softShadow,
    position: "absolute",
    zIndex: 50,
    width: "100%",
    minWidth: "14em",
    background: "#fff",
    borderRadius: 14,
    overflow: "hidden",
  },

  highlightedOption: {
    background: colours.borderGrey,
  },
  selectedOption: {
    ...gStyles.fontBold,
    background: colours.selectedLightBlue,
    color: colours.darkishBlue,
    cursor: "default",
  },
  selectedHighlightedOption: {
    background: "none",
  },
  openToggleIcon: {
    opacity: 0.3,
    transform: "scaleY(-1)",
  },
  sideButton: {
    ...gStyles.noButton,
    ...selectStyles.sideButtons,
    height: "auto",
    display: "flex",
    flex: "0 0 auto",
    flexDirection: "column",
    justifySelf: "stretch",
    alignItems: "center",
    justifyContent: "center",
    width: "max-content",
    marginRight: "0.75rem",
    textAlign: "center",
  },
  option: {
    ...gStyles.headerFilterButtonContent,

    flex: "1 1 100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",

    padding: "0.7em 1rem",

    ...gStyles.contentBox,
    borderTop: 0,
    cursor: "pointer",

    width: "100%",
    height: "3em",
    textAlign: "left",
    color: colours.oldSecondary,
    lineHeight: 1,

    position: "relative",

    ":last-child": {
      borderBottomLeftRadius: 4,
      borderBottomRightRadius: 4,
    },
    minWidth: "3.313rem",
  },
  selectedItemButton: {
    ...gStyles.headerFilterButtonSelected,
  },

  notInteracted: {
    background: "initial",
    color: "initial",
    border: `0.5px solid "initial"`,
    ":focus": {
      color: "initial",
      border: `0.5px solid "initial"`,
    },
  },
};

const overrideMenuStyles = {
  optionWithSubSectionContainer: {
    width: "100%",
  },
  optionWithSubSection: {
    ...gStyles.fontBold,
    fontSize: "0.875rem",
  },
  option: {
    padding: "0.7rem 1rem",
    ...gStyles.fontBold,
    fontSize: "0.875rem",

    ":hover": {
      background: colours.borderGrey,
    },
  },
  expanded: {
    marginLeft: "0.7rem",
  },
  item: {
    padding: "0.7rem",
  },
  parentActive: {
    paddingLeft: "0.7rem",
  },
};

function SelectDropdown(props) {
  const {
    options,
    selected,
    onChange,
    ariaLabel,
    defaultSelectedId,
    label,
    loading,
  } = props;
  const { styles, css } = useStyles(baseStyles, props);
  const [interacted, setInteracted] = useState(false);

  const childSelectedCat = hasSubOption(selected);

  const { selectedOption, selectedSubOption } = useMemo(() => {
    const selectedOption =
      options &&
      options.find((s) => {
        const subOptions = s?.subOptions;

        let selectedChild = null;
        subOptions?.forEach((element) => {
          if (
            compareFunc(childSelectedCat, element?.value) ||
            compareFunc(childSelectedCat, element?.id)
          ) {
            selectedChild = childSelectedCat;
          }
        });

        if (selectedChild) {
          return true;
        }

        return s.id === selected;
      });

    const subOptions = selectedOption?.subOptions;
    let selectedSubOption = null;
    subOptions?.forEach((element) => {
      if (
        compareFunc(childSelectedCat, element?.value) ||
        compareFunc(childSelectedCat, element?.id)
      ) {
        selectedSubOption = childSelectedCat;
      }
    });

    return {
      selectedOption,
      selectedSubOption,
    };
  }, [childSelectedCat, options, selected]);

  const handleChange = useCallback(
    (item) => {
      setInteracted(true);
      onChange && onChange(item.id);
    },
    [onChange]
  );

  return (
    <Downshift
      selectedItem={selectedOption}
      selectedSubOption={selectedSubOption}
      selected={selected}
      onChange={handleChange}
      itemToString={(layout) => layout && layout.id}
    >
      {(props) => {
        const {
          getToggleButtonProps,
          getItemProps,
          isOpen,
          selectedItem,
          highlightedIndex,
          innerRef,
          dataId,
          toggleMenu,
        } = props;

        const isDefaultOptionsSelected = defaultSelectedId !== selectedItem?.id;

        const selectedLabel = selectedItem?.title || selectedItem?.label;

        const handleSubAction = (parentId, childId) => {
          setInteracted(true);
          onChange(`${parentId}/${childId}`);
          toggleMenu();
        };

        const handleMainAction = (parentId) => {
          setInteracted(true);
          onChange(parentId);
          toggleMenu();
        };

        return (
          <h2
            className={css(styles.outer)}
            ref={innerRef}
            aria-label={ariaLabel}
          >
            <button
              className={css(
                styles.input,
                ((defaultSelectedId && isDefaultOptionsSelected) ||
                  (interacted && !defaultSelectedId)) &&
                  styles.selectedItemButton
              )}
              {...(getToggleButtonProps && getToggleButtonProps())}
              data-testid={dataId}
            >
              {selectedItem ? (
                <span className={css(styles.content, styles.text)}>
                  <span className={css(styles.textInner)}>
                    {selectedSubOption || selectedLabel}
                  </span>
                </span>
              ) : (
                <span className={css(styles.content, styles.text)}>
                  {label}
                </span>
              )}
              <div className={css(styles.sideButtons)}>
                <span className={css(styles.sideButton)}>
                  <FontAwesomeIcon
                    dataid="dropDown"
                    icon={faCaretDown}
                    className={css(
                      isOpen ? styles.openToggleIcon : styles.toggleIcon
                    )}
                  />
                </span>
              </div>
            </button>
            {isOpen && (
              <div data-testid="options" className={css(styles.options)}>
                {loading ? (
                  <div className={css(styles.option)}>
                    <FontAwesomeIcon
                      className={css(styles.icon)}
                      icon={faLoader}
                      spin
                    />
                  </div>
                ) : (
                  options.map((option, i) => {
                    const subOptions = fromJS(option?.subOptions);
                    let selectedOption =
                      selectedItem && option.id === selectedItem.id;

                    const highlighted = highlightedIndex === i;
                    const label = option.title || option.label;

                    return subOptions?.size > 0 ? (
                      <MenuItemWithSubMenu
                        key={option?.id}
                        parentOption={Map(option)}
                        item={label}
                        subOptions={subOptions}
                        handleMainAction={handleMainAction}
                        handleSubAction={handleSubAction}
                        selected={selected}
                        styles={overrideMenuStyles}
                      />
                    ) : (
                      <div
                        {...getItemProps({ item: option })}
                        data-id="layout-option"
                        className={css(
                          styles.option,
                          highlighted && styles.highlightedOption,
                          selectedOption && styles.selectedOption,
                          selectedOption &&
                            highlighted &&
                            styles.selectedHighlightedOption
                        )}
                        key={option.id}
                        title={label}
                      >
                        <span className={css(styles.text)}>{label}</span>
                        {option.additionalInfo && (
                          <span className={css(styles.text)}>
                            {option.additionalInfo}
                          </span>
                        )}
                      </div>
                    );
                  })
                )}
              </div>
            )}
          </h2>
        );
      }}
    </Downshift>
  );
}

SelectDropdown.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dataId: PropTypes.string,
  ariaLabel: PropTypes.string,
  styles: PropTypes.object,
  defaultSelectedId: PropTypes.string,
  label: PropTypes.string,
};

export default SelectDropdown;
