import { css } from "aphrodite";
import PropTypes from "prop-types";
import React, { useCallback, useState, useEffect } from "react";
import { tellMeWhyRequestPromise } from "routines/user";

import StandardButton from "components/Buttons/StandardButton";
import GenericModal from "components/Common/GenericModal";

import sendGAEvent from "utils/sendGAEvent";

import useRoutinePromises from "hooks/useRoutinePromises";
import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";

const baseStyles = {
  container: {
    display: "grid",
    gridTemplateRows: "repeat(2, max-content)",
    gridGap: "0.75rem",
  },
  headingcontainer: {
    display: "grid",
    gridTemplateRows: "repeat(2, max-content)",
    gridGap: "0.75rem",
  },
  title: {
    ...gStyles.avalonMedium,
    fontSize: "1rem",
    margin: 0,
    padding: 0,
  },
  explainer: {
    ...gStyles.avalonMedium,
    fontSize: "1rem",
    margin: 0,
    padding: 0,
    textAlign: "center",
  },
  input: {
    outline: "none",
    border: `2px solid ${colours.greyBorder}`,
    borderRadius: 12,
    height: "2.813rem",
    width: "100%",
    padding: "0.875rem 1.125rem",
    fontSize: "0.875rem",

    ":focus": {
      border: `2px solid ${colours.selectedBlue}`,
    },

    "::placeholder": {
      ...gStyles.fontLightItalic,
      letterSpacing: "0.05px",
      color: colours.midGrey,
      fontSize: "0.875rem",
    },
  },
  buttonContainer: {
    width: "100%",
    display: "flex",
    gap: "1rem",
    justifyContent: "space-between",
    marginTop: "2rem",
  },
  errorSection: {
    display: "none",
  },
  showError: {
    display: "inline-block",
    width: "100%",
    height: "max-content",
  },
  error: {
    margin: 0,
    padding: 0,
    fontSize: "0.75rem",
    color: colours.negative,
  },
};

const ButtonStyles = {
  button: {
    ...gStyles.avalonBold,
    height: "2.625rem",
    width: "10.313rem",
    fontSize: "1rem",
    borderRadius: "25px",
  },
};

const MOBILE_SLIDE_PROPS = {
  slideInFrom: "bottom",
  fullWidth: true,
  fullHeight: true,
  showOverlay: true,
  styles: {
    childrenInner: {
      height: "350px",
      position: "absolute",
      bottom: "-5px",
      borderRadius: 0,
      borderTopLeftRadius: 15,
      borderTopRightRadius: 15,
    },
  },
};

const TellMeWhyPromptModal = (props) => {
  const { closeModal, mobile } = props;
  const { styles } = useStyles(baseStyles, props);

  const [prompt, setPrompt] = useState("");
  const [error, setError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [successMessage, setShowSuccessMessage] = useState(false);

  const { tellMeWhyRequest } = useRoutinePromises({
    tellMeWhyRequest: tellMeWhyRequestPromise,
  });

  useEffect(() => {
    sendGAEvent({
      action: "TellMeWhyPromptModalOpened",
      context: "Tell Me Why Prompt",
    });
  }, []);

  const handleSubmitTellMeWhy = useCallback(() => {
    if (prompt.trim() !== "") {
      setSubmitting(true);
      sendGAEvent({
        action: "submitTellMeWhyButtonClicked",
        prompt: prompt,
        context: "Tell Me Why Prompt",
      });

      tellMeWhyRequest({
        prompt: prompt,
      })
        .then(() => {
          setSubmitting(false);
          setShowSuccessMessage(true);
        })
        .catch((e) => {
          setSubmitting(false);
          setError(e.message);
        });
    }
  }, [prompt, tellMeWhyRequest]);

  const handleValidation = useCallback(() => {
    if (prompt.trim() === "") {
      setError("Please ensure all fields are filled");
      return;
    }
    handleSubmitTellMeWhy();
  }, [prompt, handleSubmitTellMeWhy]);

  const handleCancelAddingTellMeWhy = useCallback(() => {
    sendGAEvent({
      action: "cancelTellMeWhyPromptButtonClicked",
      context: "Tell Me Why Prompt",
    });
    closeModal();
  }, [closeModal]);

  const handleInputChange = useCallback(
    (e) => {
      setPrompt(e.target.value);
    },
    [setPrompt]
  );

  const handleKeyDown = useCallback((e) => {
    e.target.style.height = "auto";
    e.target.style.height = `${e.target.scrollHeight}px`;
  }, []);

  const renderContent = useCallback(() => {
    return successMessage ? (
      <div className={css(styles.container)}>
        <div className={css(styles.headingcontainer)}>
          <h2 className={css(styles.explainer)}>
            Your info has been saved and can be used with the "Tell Me Why"
            button.
          </h2>
        </div>
        <div className={css(styles.buttonContainer)}>
          <StandardButton
            label="OK"
            variation="white"
            flat
            withPadding
            customStyles={ButtonStyles}
            onClick={handleCancelAddingTellMeWhy}
          />
        </div>
      </div>
    ) : (
      <div className={css(styles.container)}>
        <div className={css(styles.headingcontainer)}>
          <h2 className={css(styles.title)}>
            Tell us about your current client, campaign goals, and anything that
            will help understand if a podcast is a good fit for you.
          </h2>
          <textarea
            data-testid="input-area"
            className={css(styles.input)}
            placeholder="Type here..."
            onKeyDown={handleKeyDown}
            onChange={handleInputChange}
          />
          <div className={css(styles.errorSection, error && styles.showError)}>
            {error && <p className={css(styles.error)}>{error}</p>}
          </div>
        </div>
        <div className={css(styles.buttonContainer)}>
          <StandardButton
            label="Save"
            variation="pink"
            flat
            withPadding
            customStyles={ButtonStyles}
            submitting={submitting}
            onClick={handleValidation}
          />
        </div>
      </div>
    );
  }, [
    error,
    handleCancelAddingTellMeWhy,
    handleInputChange,
    handleKeyDown,
    handleValidation,
    styles.buttonContainer,
    styles.container,
    styles.error,
    styles.errorSection,
    styles.explainer,
    styles.headingcontainer,
    styles.input,
    styles.showError,
    styles.title,
    submitting,
    successMessage,
  ]);

  return (
    <GenericModal
      {...props}
      renderContent={renderContent}
      slideProps={mobile ? MOBILE_SLIDE_PROPS : null}
      onRequestClose={handleCancelAddingTellMeWhy}
    />
  );
};

TellMeWhyPromptModal.propTypes = {
  closeModal: PropTypes.func,
  mobile: PropTypes.bool,
  closer: PropTypes.func,
};

export default TellMeWhyPromptModal;
