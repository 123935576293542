import { List } from "immutable";
import { useMemo } from "react";

import {
  makeSelectPlatformFilters,
  makeSelectAppleCategoriesFilters,
  makeSelectAppleCountriesFilters,
  makeSelectSpotifyCategoriesFilters,
  makeSelectSpotifyCountryFilters,
  makeSelectFailedFilters,
  makeSelectLodingFilters,
} from "selectors/charts";

import useReduxState from "hooks/useReduxState";

export function useChartFilters() {
  const selectPlatformFilters = makeSelectPlatformFilters();
  const selectAppleCategoriesFilters = makeSelectAppleCategoriesFilters();
  const selectAppleCountriesFilters = makeSelectAppleCountriesFilters();
  const selectSpotifyCategoriesFilters = makeSelectSpotifyCategoriesFilters();
  const selectSpotifyCountryFilters = makeSelectSpotifyCountryFilters();
  const selectLodingFilters = makeSelectLodingFilters();
  const selectFailedFilters = makeSelectFailedFilters();

  let platformOptionsFromState = useReduxState(
    (state) => selectPlatformFilters(state),
    []
  );

  // selection boxes are expecting platform options to be a raw array, but if we load them on the server
  // they get turned into a List on hydration. We need to transform them back to an array if necessary
  const platformOptions = useMemo(
    () =>
      List.isList(platformOptionsFromState)
        ? platformOptionsFromState.toJS()
        : platformOptionsFromState,
    [platformOptionsFromState]
  );

  const appleCategoryOptions = useReduxState(
    (state) => selectAppleCategoriesFilters(state),
    []
  );

  const appleCountryOptions = useReduxState(
    (state) => selectAppleCountriesFilters(state),
    []
  );

  const spotifyCategoryOptions = useReduxState(
    (state) => selectSpotifyCategoriesFilters(state),
    []
  );
  const spotifyCountryOptions = useReduxState(
    (state) => selectSpotifyCountryFilters(state),
    []
  );

  const filtersLoading = useReduxState(
    (state) => selectLodingFilters(state),
    []
  );

  const filtersFailed = useReduxState(
    (state) => selectFailedFilters(state),
    []
  );

  return {
    platformOptions,
    appleCategoryOptions,
    appleCountryOptions,
    spotifyCategoryOptions,
    spotifyCountryOptions,
    filtersLoading,
    filtersFailed,
  };
}

export default useChartFilters;
