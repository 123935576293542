import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const Everything = Loadable(() => import("./Everything"));

function EverythingAsyncLoading(props) {
  return <LoadableLoading {...props} page />;
}

const EverythingContainer = (props) => {
  return <Everything {...props} fallback={<EverythingAsyncLoading />} />;
};

export default EverythingContainer;
