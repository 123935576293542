import { commonModuleGroup, podcastModuleGroup } from "modules/groups";
import { BLOG_MODULE } from "modules/moduleIds";
import { Redirect, useParams, Switch } from "react-router-dom";

import BlogAuthorView from "pages/Blog/BlogAuthorView/async";
import BlogCategoryIndex from "pages/Blog/BlogCategoryIndex/async";
import BlogCategoryView from "pages/Blog/BlogCategoryView/async";
import BlogIndex from "pages/Blog/BlogIndex/async";
import BlogPostViewContainer from "pages/Blog/BlogPostView/async";
import TopLevelRoute from "pages/Common/TopLevelRoute";

import { BLOG_BASE_PATH } from "../utils/blog";

function BlogRouteRedirect() {
  let { slug, category } = useParams();

  return <Redirect to={`${BLOG_BASE_PATH}/${category}/${slug}`} />;
}

function BlogRoutes({ topLevelRouteProps }) {
  return (
    <Switch>
      <TopLevelRoute
        path={`${BLOG_BASE_PATH}/authors/:slug`} // Changed to 'authors' from 'author' so we can set up an author hub later
        {...topLevelRouteProps}
        transparent={false}
        modules={[...podcastModuleGroup, BLOG_MODULE]}
      >
        <BlogAuthorView />
      </TopLevelRoute>

      <TopLevelRoute
        path={`${BLOG_BASE_PATH}/categories`}
        {...topLevelRouteProps}
        transparent={false}
        modules={[...commonModuleGroup, BLOG_MODULE]}
      >
        <BlogCategoryIndex />
      </TopLevelRoute>

      <TopLevelRoute
        path={`${BLOG_BASE_PATH}/discover/:category/:slug`}
        {...topLevelRouteProps}
        transparent={false}
        modules={[...commonModuleGroup, ...podcastModuleGroup, BLOG_MODULE]}
      >
        <BlogRouteRedirect />
      </TopLevelRoute>

      <TopLevelRoute
        path={`${BLOG_BASE_PATH}/:category/:slug`}
        {...topLevelRouteProps}
        transparent={false}
        modules={[...commonModuleGroup, ...podcastModuleGroup, BLOG_MODULE]}
      >
        <BlogPostViewContainer />
      </TopLevelRoute>

      <TopLevelRoute
        path={`${BLOG_BASE_PATH}/:slug`}
        {...topLevelRouteProps}
        transparent={false}
        modules={[...commonModuleGroup, BLOG_MODULE]}
      >
        <BlogCategoryView />
      </TopLevelRoute>

      <TopLevelRoute
        path={BLOG_BASE_PATH}
        exact
        {...topLevelRouteProps}
        transparent={false}
        modules={[...commonModuleGroup, BLOG_MODULE]}
      >
        <BlogIndex />
      </TopLevelRoute>
    </Switch>
  );
}

export default BlogRoutes;
