import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import { isImmutable } from "immutable";
import PropTypes from "prop-types";
import deepmerge from "ramda/src/mergeDeepRight";
import { useMemo } from "react";

import BaseModal from "components/Common/Modals/BaseModal";

import ModalCloseButton from "./Modals/ModalCloseButton";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";

const baseStyles = {
  genericModal: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FFF",
    boxShadow: "0 5px 16px 0 rgba(0,0,0,0.07)",
    position: "relative",
    width: "100%",
    height: "100%",
    borderRadius: 6,
  },
  genericModalWithTitle: {},
  genericModalWithSmallTitle: {
    padding: 0,
  },
  heroIcon: {
    textAlign: "center",
    fontSize: "1.25rem",
    color: "#bbb",
    marginTop: "1.5rem",
  },
  title: {
    ...gStyles.avalonBold,
    color: colours.black,
    display: "flex",
    justifyContent: "center",
    fontSize: "1.25rem",
    textAlign: "center",
    padding: "1.5rem 2rem 0",
  },
  smallTitle: {
    fontSize: ".813rem",
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
    paddingTop: "1rem",
    paddingBottom: "1rem",
  },
  contentContainer: {
    padding: "1.5rem 1.5rem 1.5rem",
  },
  contentContainerWithSmallTitle: {
    padding: "1.5rem 1.5rem 1.5rem",
  },
  description: {
    ...gStyles.fontRegular,
    display: "flex",
    justifyContent: "center",
    fontSize: "0.9125rem",
    lineHeight: 1.6,
    textAlign: "center",
  },
};

const GenericModal = (props) => {
  const {
    title,
    content,
    renderContent,
    disableClose,
    heroIcon,
    slideProps,
    smallTitle,
    closeButtonStyles,
    onRequestClose,
    onClose,
    noContentContainer,
    hideClose,
    modalStyles,
  } = props;

  const genericModalStyles = useMemo(() => {
    if (modalStyles) {
      return deepmerge(
        baseStyles,
        modalStyles?.toJS ? modalStyles.toJS() : modalStyles
      );
    }

    return baseStyles;
  }, [modalStyles]);

  const { styles } = useStyles(genericModalStyles, props);

  const renderModalContent = ({ onClose }) => {
    let displayContent = content;

    if (renderContent) {
      displayContent = renderContent({ onClose });
    } else if (typeof content === "string") {
      displayContent = <div className={css(styles.description)}>{content}</div>;
    }

    return (
      <div
        className={css(
          styles.genericModal,
          title && styles.genericModalWithTitle,
          smallTitle && title && styles.genericModalWithSmallTitle
        )}
      >
        {!hideClose && (
          <ModalCloseButton
            onClose={onClose}
            disableClose={disableClose}
            styles={closeButtonStyles || props.styles}
            data-id="generic-modal-close-button"
          />
        )}
        {heroIcon && (
          <div className={css(styles.heroIcon)}>
            <FontAwesomeIcon
              icon={heroIcon?.toJS ? heroIcon.toJS() : heroIcon}
            />
          </div>
        )}
        {title && (
          <div className={css(styles.title, smallTitle && styles.smallTitle)}>
            {title}
          </div>
        )}
        {displayContent && !noContentContainer ? (
          <div className={css(styles.contentContainer)}>{displayContent}</div>
        ) : (
          displayContent
        )}
      </div>
    );
  };

  return (
    <BaseModal
      {...props}
      onRequestClose={onRequestClose || onClose}
      slideProps={isImmutable(slideProps) ? slideProps.toJS() : slideProps}
      renderContent={renderModalContent}
    />
  );
};

GenericModal.propTypes = {
  title: PropTypes.string,
  content: PropTypes.node,
  renderContent: PropTypes.func,
  disableClose: PropTypes.bool,
  heroIcon: PropTypes.object,
  slideProps: PropTypes.object,
  styles: PropTypes.object,
  smallTitle: PropTypes.bool,
  closeButtonStyles: PropTypes.object,
  onRequestClose: PropTypes.func,
  onClose: PropTypes.func,
  noContentContainer: PropTypes.bool,
  hideClose: PropTypes.bool,
  modalStyles: PropTypes.object,
};

GenericModal.defaultProps = {
  title: null,
  content: null,
  renderContent: null,
  disableClose: false,
  heroIcon: null,
  slideProps: null,
  styles: null,
  smallTitle: false,
  closeButtonStyles: null,
  onRequestClose: null,
  onClose: null,
  noContentContainer: false,
  hideClose: false,
  modalStyles: null,
};

export default GenericModal;
