import throttle from "lodash/throttle";
import { useState, useEffect } from "react";

export const useScrollPosition = (condition, throttleNumber = 250) => {
  const [scrollPosition, setScrollPosition] = useState(0);

  const updatePosition = () => {
    const position = window.pageYOffset;

    if (condition) {
      setScrollPosition(position);
    }
  };

  const updatePositionThrottled = throttle(updatePosition, throttleNumber);

  useEffect(() => {
    window.addEventListener("scroll", updatePositionThrottled);
    updatePositionThrottled();
    return () => window.removeEventListener("scroll", updatePositionThrottled);

    // eslint-disable-next-line
  }, [condition]);

  return scrollPosition;
};
