import { css } from "aphrodite";
import PropTypes from "prop-types";
import { memo, Fragment } from "react";

import EmailVerificationBanner from "components/Banners/EmailVerificationBannerAsync";
import HeaderStandard from "components/Layout/Header/HeaderStandard";
import NavMenu from "components/Layout/NavMenu/NavMenu";

import { useLocalStorage } from "usehooks-ts";
import useLoggedInUser, { useLoggedIn } from "hooks/useLoggedInUser";
import { useStyles } from "hooks/useStyles";

import { createBreakpoint } from "styles/ScreenSizes";

const baseStyles = {
  container: {},
  secondNav: {
    position: "relative",
    display: "block",
    [createBreakpoint({ min: 1400 })]: {
      display: "none",
    },
  },
};

const transparentStyles = {
  container: {
    position: "absolute",
    width: "100%",
    background: "none",
    boxShadow: "none",
    zIndex: 15,
  },
  secondNav: {
    position: "relative",
    display: "block",
    [createBreakpoint({ min: 1400 })]: {
      display: "none",
    },
  },
};

function HeaderNavWrapper(props) {
  const { withHeader, withNavMenu, transparent, showUserContainer, ...rest } =
    props;

  const { styles } = useStyles(
    [baseStyles, transparent && transparentStyles],
    props
  );

  const [emailBannerIsDismissed] = useLocalStorage(
    "pc_banner_dismissed:email_verification",
    null
  );

  const loggedIn = useLoggedIn();
  const user = useLoggedInUser();

  if (!withHeader) {
    return null;
  }

  return (
    <Fragment>
      {loggedIn && !user.get("email_verified") && !emailBannerIsDismissed && (
        <EmailVerificationBanner transparent={transparent} />
      )}
      <div className={css(styles.container)}>
        <HeaderStandard
          key="header"
          showUserContainer={showUserContainer}
          transparent={transparent}
          loggedIn={loggedIn}
          {...rest}
          styles={undefined}
        />
        <div className={css(styles.secondNav)}>
          {withNavMenu && (
            <NavMenu
              transparent={transparent}
              key="nav-menu"
              user={user}
              {...rest}
              styles={undefined}
            />
          )}
        </div>
      </div>
    </Fragment>
  );
}

HeaderNavWrapper.propTypes = {
  withHeader: PropTypes.bool,
  withNavMenu: PropTypes.bool,
  transparent: PropTypes.bool,
  showUserContainer: PropTypes.bool,
};

HeaderNavWrapper.defaultProps = {
  withNavMenu: true,
  withHeader: false,
  transparent: false,
  showUserContainer: false,
};

// HeaderNavWrapper.whyDidYouRender = true;

export default memo(HeaderNavWrapper);
