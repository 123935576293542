import debounce from "lodash/debounce";
import PropTypes from "prop-types";
import { Component } from "react";

export default class WindowResizeListener extends Component {
  static propTypes = {
    onResize: PropTypes.func.isRequired,
  };

  static displayName = "WindowResizeListener";

  static _listeners = [];

  static DEBOUNCE_TIME = 100;

  static _onResize = () => {
    const windowWidth =
      document.documentElement.clientWidth ||
      document.body.clientWidth ||
      window.innerWidth;
    const windowHeight =
      document.documentElement.clientHeight ||
      document.body.clientHeight ||
      window.innerHeight;

    WindowResizeListener._listeners.forEach((listener) => {
      listener({
        windowWidth,
        windowHeight,
      });
    });
  };

  shouldComponentUpdate(nextProps) {
    return nextProps.onResize !== this.props.onResize;
  }

  componentDidMount() {
    // Defer creating _debouncedResize until it's mounted
    // This allows users to change DEBOUNCE_TIME if they want
    // If there's no listeners, we need to attach the window listener
    if (!WindowResizeListener._listeners.length) {
      WindowResizeListener._debouncedResize = debounce(
        WindowResizeListener._onResize,
        WindowResizeListener.DEBOUNCE_TIME
      );
      window.addEventListener(
        "resize",
        WindowResizeListener._debouncedResize,
        false
      );
    }

    WindowResizeListener._listeners.push(this.props.onResize);
    WindowResizeListener._debouncedResize();
  }

  componentWillUnmount() {
    const idx = WindowResizeListener._listeners.indexOf(this.props.onResize);

    WindowResizeListener._listeners.splice(idx, 1);

    if (!WindowResizeListener._listeners.length) {
      window.removeEventListener(
        "resize",
        WindowResizeListener._debouncedResize,
        false
      );
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.onResize !== this.props.onResize) {
      const idx = WindowResizeListener._listeners.indexOf(this.props.onResize);

      WindowResizeListener._listeners.splice(idx, 1, nextProps.onResize);
    }
  }

  render() {
    return null;
  }
}
