import { css } from "aphrodite";
import { markdown } from "markdown";
import PropTypes from "prop-types";
import React, { useCallback, useState, useEffect } from "react";
import { loadTellMeWhyPromise } from "routines/podcast";
import { tellMeWhyRequestPromise } from "routines/user";

import StandardButton from "components/Buttons/StandardButton";
import GenericModal from "components/Common/GenericModal";
import LoadingOverlay from "components/Common/LoadingOverlay";

import sendGAEvent from "utils/sendGAEvent";

import useLoggedInUser from "hooks/useLoggedInUser";
import usePodcastData from "hooks/usePodcastData";
import useRoutinePromises from "hooks/useRoutinePromises";
import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";

const baseStyles = {
  container: {
    display: "grid",
    gridTemplateRows: "repeat(3, max-content)",
    gridGap: "0.75rem",
  },
  headingcontainer: {
    display: "grid",
    gridTemplateRows: "repeat(2, max-content)",
    gridGap: "0.75rem",
  },
  myGoals: {
    paddingBottom: "1rem",
    display: "grid",
    gridTemplateRows: "max-content 1fr",
    gridGap: "0.25rem",
    borderBottom: `1px solid ${colours.borderGrey}`,
  },
  result: {
    display: "grid",
    gridTemplateRows: "minmax(auto, 320px)",
    overflow: "auto",
    gridGap: "0.5rem",
  },
  content: {
    overflow: "auto",
  },
  title: {
    ...gStyles.avalonMedium,
    fontSize: "1rem",
    margin: 0,
    padding: 0,
  },
  goalsTitle: {
    ...gStyles.avalonBold,
    fontSize: "0.813rem",
    margin: 0,
    padding: 0,
  },
  goal: {
    ...gStyles.fontRegular,
    fontSize: "0.813rem",
    margin: 0,
    padding: 0,
  },
  input: {
    outline: "none",
    border: `2px solid ${colours.greyBorder}`,
    borderRadius: 12,
    height: "2.813rem",
    width: "100%",
    padding: "0.875rem 1.125rem",
    fontSize: "0.875rem",

    ":focus": {
      border: `2px solid ${colours.selectedBlue}`,
    },

    "::placeholder": {
      ...gStyles.fontLightItalic,
      letterSpacing: "0.05px",
      color: colours.midGrey,
      fontSize: "0.875rem",
    },
  },
  buttonContainer: {
    width: "100%",
    display: "flex",
    gap: "1rem",
    justifyContent: "space-between",
    marginTop: "1rem",
    paddingTop: "1rem",
    borderTop: `1px solid ${colours.borderGrey}`,
  },
  errorSection: {
    display: "none",
  },
  showError: {
    display: "inline-block",
    width: "100%",
    height: "max-content",
  },
  error: {
    margin: 0,
    padding: 0,
    fontSize: "0.75rem",
    color: colours.negative,
  },
};

const ButtonStyles = {
  button: {
    ...gStyles.avalonBold,
    height: "2.625rem",
    width: "10.313rem",
    fontSize: "1rem",
    borderRadius: "25px",
    marginLeft: 0,
    marginRight: 0,
  },
};

const MOBILE_SLIDE_PROPS = {
  slideInFrom: "bottom",
  fullWidth: true,
  fullHeight: true,
  showOverlay: true,
  styles: {
    childrenInner: {
      // TODO: These styles can be shared between any modals that need to slide up like this on mobile
      height: "350px",
      position: "absolute",
      bottom: "-5px",
      borderRadius: 0,
      borderTopLeftRadius: 15,
      borderTopRightRadius: 15,
    },
  },
};

const TellMeWhyResultModal = (props) => {
  const { closeModal, mobile, podcastId } = props;
  const { styles } = useStyles(baseStyles, props);

  const podcast = usePodcastData(podcastId);
  const rawGptResponse = podcast?.get("tellMeWhyResponse");

  const user = useLoggedInUser();
  const tellMyWhyPrompt = user?.get("profile_prompt");

  const [prompt, setPrompt] = useState(tellMyWhyPrompt);
  const [error, setError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submittingUpdate, setSubmittingUpdate] = useState(false);
  const [renderEditGoals, setRenderEditGoals] = useState(false);

  const { tellMeWhyRequest, loadTellMeWhy } = useRoutinePromises({
    tellMeWhyRequest: tellMeWhyRequestPromise,
    loadTellMeWhy: loadTellMeWhyPromise,
  });

  useEffect(() => {
    sendGAEvent({
      action: "TellMeWhyPromptModalOpened",
      context: "Tell Me Why Prompt",
    });
  }, []);

  const handleInputChange = useCallback(
    (e) => {
      setPrompt(e.target.value);
    },
    [setPrompt]
  );

  const handleKeyDown = useCallback((e) => {
    e.target.style.height = "auto";
    e.target.style.height = `${e.target.scrollHeight}px`;
  }, []);

  const handleSubmitTellMeWhy = useCallback(() => {
    if (prompt.trim() !== "") {
      setSubmittingUpdate(true);
      sendGAEvent({
        action: "submitTellMeWhyButtonClicked",
        prompt: prompt,
        context: "Tell Me Why Edit Button",
      });

      tellMeWhyRequest({
        prompt: prompt,
      })
        .then(() => {
          setSubmittingUpdate(false);
          setSubmitting(true);
          setRenderEditGoals(false);
          loadTellMeWhy({ podcastId: podcastId })
            .then(() => {
              setSubmitting(false);
            })
            .catch((e) => {
              setSubmitting(false);
            });
        })
        .catch((e) => {
          setSubmittingUpdate(false);
          setError(e.message);
        });
    }
  }, [loadTellMeWhy, podcastId, prompt, tellMeWhyRequest]);

  const handleValidation = useCallback(() => {
    if (prompt.trim() === "") {
      setError("Please ensure all fields are filled");
      return;
    }
    handleSubmitTellMeWhy();
  }, [prompt, handleSubmitTellMeWhy]);

  const handleCancelAddingTellMeWhy = useCallback(() => {
    if (renderEditGoals) {
      handleValidation();
    } else {
      sendGAEvent({
        action: "cancelTellMeWhyPromptButtonClicked",
        context: "Tell Me Why Prompt",
      });
      closeModal();
    }
  }, [closeModal, handleValidation, renderEditGoals]);

  const handleEditPrompt = useCallback(() => {
    if (renderEditGoals) {
      setRenderEditGoals(false);
    } else {
      setRenderEditGoals(true);
    }
  }, [renderEditGoals]);

  const renderModalContent = useCallback(() => {
    if (renderEditGoals) {
      return (
        <div className={css(styles.container)}>
          <div className={css(styles.headingcontainer)}>
            <h2 className={css(styles.title)}>
              Tell us about your current client, campaign goals, and anything
              that will help understand if a podcast is a good fit for you.
            </h2>
            <textarea
              data-testid="input-area"
              className={css(styles.input)}
              placeholder="Type here..."
              onKeyDown={handleKeyDown}
              value={prompt}
              onChange={handleInputChange}
            />
            <div
              className={css(styles.errorSection, error && styles.showError)}
            >
              {error && <p className={css(styles.error)}>{error}</p>}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className={css(styles.headingcontainer)}>
        <div className={css(styles.myGoals)}>
          <h3 className={css(styles.goalsTitle)}>Your Goals</h3>
          <h5 className={css(styles.goal)}>{tellMyWhyPrompt}</h5>
        </div>
        {submitting ? (
          <LoadingOverlay noOverly noPadding />
        ) : (
          <div className={css(styles.result)}>
            <span
              className={css(styles.content)}
              dangerouslySetInnerHTML={{
                __html: markdown.toHTML(rawGptResponse),
              }}
            />
          </div>
        )}
      </div>
    );
  }, [
    error,
    handleInputChange,
    handleKeyDown,
    prompt,
    rawGptResponse,
    renderEditGoals,
    styles.container,
    styles.content,
    styles.error,
    styles.errorSection,
    styles.goal,
    styles.goalsTitle,
    styles.headingcontainer,
    styles.input,
    styles.myGoals,
    styles.result,
    styles.showError,
    styles.title,
    submitting,
    tellMyWhyPrompt,
  ]);

  const renderContent = useCallback(() => {
    return (
      <div className={css(styles.container)}>
        {renderModalContent()}
        <div className={css(styles.buttonContainer)}>
          <StandardButton
            label={renderEditGoals ? "Back" : "Edit My Goals"}
            variation={renderEditGoals ? "white" : "pink"}
            flat
            withPadding
            customStyles={ButtonStyles}
            onClick={handleEditPrompt}
          />
          <StandardButton
            label={renderEditGoals ? "Save" : "OK"}
            variation={renderEditGoals ? "pink" : "white"}
            flat
            withPadding
            customStyles={ButtonStyles}
            submitting={submittingUpdate}
            onClick={handleCancelAddingTellMeWhy}
          />
        </div>
      </div>
    );
  }, [
    handleCancelAddingTellMeWhy,
    handleEditPrompt,
    renderEditGoals,
    renderModalContent,
    styles.buttonContainer,
    styles.container,
    submittingUpdate,
  ]);

  return (
    <GenericModal
      {...props}
      renderContent={renderContent}
      slideProps={mobile ? MOBILE_SLIDE_PROPS : null}
      onRequestClose={handleCancelAddingTellMeWhy}
    />
  );
};

TellMeWhyResultModal.propTypes = {
  closeModal: PropTypes.func,
  mobile: PropTypes.bool,
  closer: PropTypes.func,
};

export default TellMeWhyResultModal;
