import PropTypes from "prop-types";
import {
  createContext,
  memo,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { Route } from "react-router-dom";

import LoadingOverlay from "components/Common/LoadingOverlay";
import ChunkLoadErrorBoundary from "components/Errors/ChunkLoadErrorBoundary";
import ErrorBoundary from "components/Errors/ErrorBoundary";
import HeaderNavWrapper from "components/Layout/Header/HeaderNavWrapper";
import RequestContext from "pages/RequestContext";

import TopLevelRouteStructure from "./TopLevelRouteStructure";

import useAddModules from "hooks/useAddModules";
import { useStyles } from "hooks/useStyles";

const baseStyles = {
  wrapper__body: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    minHeight: 780,
    flex: "1 1 100%",
    overflow: "hidden",
  },
  wrapper__content: {
    display: "flex",
    flex: "none",
    flexDirection: "column",
    minHeight: 0,
    minWidth: 0,
  },
};

export const LoadableContext = createContext({
  loadableChange: () => null,
});

function TopLevelRoute(props) {
  const {
    asyncComponent,
    children,
    headerContentContainerBackgroundColor,
    headerNavWrapperStyles,
    height,
    isHomepage,
    loadingStyles,
    modules,
    onMenuToggleClick,
    path,
    routeComponent,
    searchBarBackgroundColor,
    transparent,
    withFooter,
    withHeader,
    withNavMenu,
    ...rest
  } = props;

  const { styles, css } = useStyles(baseStyles, props);

  const requestContext = useContext(RequestContext);

  const [hasDoneLoadable, setHasDoneLoadable] = useState(
    !!requestContext.server
  );

  const { allModulesLoaded } = useAddModules({ modules, path });

  const loadableChange = useCallback(
    (up) => {
      if (!hasDoneLoadable) {
        setHasDoneLoadable(true);
      }
    },
    [hasDoneLoadable]
  );

  const loadableContext = useMemo(
    () => ({
      loadableChange,
    }),
    [loadableChange]
  );

  const RouteComponent = routeComponent || Route;

  return (
    <RouteComponent path={path} {...rest}>
      <ErrorBoundary>
        <ChunkLoadErrorBoundary>
          <TopLevelRouteStructure
            transparent={transparent}
            withFooter={withFooter}
            key="Client"
            styles={props.styles}
            header={
              <HeaderNavWrapper
                showUserContainer
                withHeader={withHeader}
                onMenuToggleClick={onMenuToggleClick}
                transparent={transparent}
                searchBarBackgroundColor={searchBarBackgroundColor}
                headerContentContainerBackgroundColor={
                  headerContentContainerBackgroundColor
                }
                withNavMenu={withNavMenu}
                styles={headerNavWrapperStyles}
                isHomepage={isHomepage}
              />
            }
          >
            <LoadableContext.Provider value={loadableContext}>
              <div className={css(styles.wrapper__body)}>
                <div className={css(styles.wrapper__content)}>
                  {allModulesLoaded ? (
                    children
                  ) : (
                    <>
                      {asyncComponent ? (
                        asyncComponent
                      ) : (
                        <LoadingOverlay
                          key="topLevelRouteLoadingOverlay"
                          height={height}
                          styles={loadingStyles}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </LoadableContext.Provider>
          </TopLevelRouteStructure>
        </ChunkLoadErrorBoundary>
      </ErrorBoundary>
    </RouteComponent>
  );
}

TopLevelRoute.propTypes = {
  withHeader: PropTypes.bool,
  withFooter: PropTypes.bool,
  transparent: PropTypes.bool,
  modules: PropTypes.array,
  path: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  component: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.element,
  ]),
  routeComponent: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.element,
  ]),
  searchBarBackgroundColor: PropTypes.string,
  headerContentContainerBackgroundColor: PropTypes.string,
  onMenuToggleClick: PropTypes.func,
  withNavMenu: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  headerNavWrapperStyles: PropTypes.object,
  loadingStyles: PropTypes.object,
  isHomepage: PropTypes.bool,
};

TopLevelRoute.defaultProps = {
  withFooter: true,
  withHeader: true,
  transparent: true,
  modules: [],
  path: null,
  component: null,
  routeComponent: null,
  searchBarBackgroundColor: null,
  headerContentContainerBackgroundColor: null,
  onMenuToggleClick: null,
  withNavMenu: true,
};

export default memo(TopLevelRoute);
