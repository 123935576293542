import colours from "./colours";
import tokens from "./tokens/tokens";
import cardTokens from "./tokens/tokens-card";

import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const fontStyles = {
  cardPrimaryTitle: {
    standard: {
      ...gStyles.avalonBold,
      color: colours.black,
      fontSize: "1.0625rem",
      margin: 0,
      padding: 0,
    },
    mobile: {
      ...gStyles.avalonBold,
      fontSize: "1rem",
      lineHeight: "17px",
      color: colours.black,
      margin: 0,
      padding: 0,
    },
  },
  cardPrimaryTitleSmaller: {
    standard: {
      ...gStyles.avalonBold,
      color: colours.black,
      fontSize: ".875rem",
      margin: 0,
      padding: 0,
    },
    mobile: {
      ...gStyles.avalonBold,
      color: colours.black,
      lineHeight: "17px",
      margin: 0,
      padding: 0,
    },
  },
  activityListItemTitle: {
    ...gStyles.avalonBold,
    color: colours.black,
    fontSize: "1rem",
  },
  cardDescription: {
    standard: {
      ...gStyles.fontRegular,
      color: colours.black,
      fontSize: 13,
      lineHeight: 1.5,
    },
    mobile: {
      ...gStyles.fontRegular,
      color: colours.black,
      fontSize: 14,
      lineHeight: "22px", // will need to be in pixels so the maxHeight can be set correctly and content doesn't get cut off
    },
  },
  cardDescriptionLink: {
    ...gStyles.fontBold,
    color: colours.black,
  },
  episodePodcastName: {
    ...gStyles.avalonMedium,
    color: "#5a616d",
    fontSize: "0.875rem",
  },
  episodePodcastNameSmall: {
    ...gStyles.avalonMedium,
    color: "#5a616d",
    fontSize: "0.8125rem",
  },
  cardUsername: {
    ...gStyles.avalonSemiBold,
    color: "#b3b3b3",
    fontSize: "1rem",
    lineHeight: "1.2",
  },
  verbText: {
    ...gStyles.fontRegular,
  },
};

const getFontStyles = (type, size) =>
  fontStyles[type]
    ? fontStyles[type][size] ||
      fontStyles[type].standard ||
      fontStyles[type].desktop ||
      fontStyles[type]
    : null;

export const cardImageSizes = {
  cardEntity: "7rem",
  mobileCardEntity: "4rem",
  mobileCompactCardEntity: "2.75rem",
  compactCardEntity: "4rem",

  mobilePodcastCardEntity: "14rem",

  introImage: "2.25rem",
  introImageMobile: "2.5rem",

  cardEntityOverviewEntity: "3rem",
  mobileCardEntityOverviewEntity: "3rem",
};

export const cardSizes = {
  // Card paddings
  standardPadding: "0.75rem",
  mobileStandardPadding: "0.75rem",

  buttonsFromRightSide: 0,
  mobileButtonsFromRightSide: "0.75rem",
  buttonsFromLeftSide: 0,
  mobileButtonsFromLeftSide: "0.75rem",

  buttonsFromTopSide: 0,
  mobileButtonsFromTopSide: 0,

  paddingBetweenReviewAndEntity: "0.5rem",
  mobilePaddingBetweenReviewAndEntity: "0.5rem",

  paddingBetweenThumbnailAndInfo: "1rem",
  mobilePaddingBetweenThumbnailAndInfo: "0.75rem",

  paddingBetweenEntityOverviewThumbnailAndInfo: "0.75rem",
  mobilePaddingBetweenEntityOverviewThumbnailAndInfo: "0.75rem",

  paddingBetweenDividerAndButtons: "0.8125rem",
  mobilePaddingBetweenDividerAndButtons: "0.8125rem",

  verticalPaddingOnCardsInPanel: "0.25rem",
  mobileVerticalPaddingOnCardsInPanel: "0.25rem",

  // Review Bubbles

  reviewBubblePadding: "0.75rem 0.75rem 0.75rem 1.15em", // New left padding to match additional padding given to items on right side
  mobileReviewBubblePadding: "8px 11px 9px",

  reviewBubblePaddingNoContent: "0.375rem 0.75rem 0.375rem 0.375rem",
  mobileReviewBubblePaddingNoContent: "0.375rem 0.75rem 0.375rem 0.375rem",

  //

  paddingForInfoIconsFirstInBottomRow: "0.25rem",
  mobilePaddingForInfoIconsFirstInBottomRow: "0.25rem",

  // Card colour lines

  colourBorderWidth: 5,
  mobileColourBorderWidth: 4,

  bottomRowIconSize: 30,

  raisedCardPadding: 18,
  mobileRaisedCardPadding: 12,

  flatCardPadding: 18,
  mobileFlatCardPadding: 12,
};

export const mobileFlatCardStyles = {
  backgroundColor: "#FFF",
  borderRadius: 20,
  border: `1px solid ${tokens.color.border.default}`,
  boxShadow: "none",
  padding: cardSizes.mobileFlatCardPadding,
};

const mobileCardStyles = {
  mobileFlatCard: {
    ...mobileFlatCardStyles,
  },
  mobileActivityCard: {
    boxShadow: cardTokens.boxShadow,
    marginBottom: "1rem",
    padding: cardSizes.mobileRaisedCardPadding,
    width: "100%",
    maxWidth: "100%",
    display: "flex",
    textAlign: "left",
    position: "relative",
  },
  mobileCard: {
    width: "100%",
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: cardTokens.color.background.default,
    boxShadow: "none",
    [ScreenSizes.mdAndAbove]: {
      boxShadow: "none",
    },
  },
  mobileCompactCardEntityImage: {
    display: "block",
    background: "rgba(0,0,0,0.04)",
    height: cardImageSizes.mobileCompactCardEntity,
    minWidth: cardImageSizes.mobileCompactCardEntity,
    width: cardImageSizes.mobileCompactCardEntity,
  },
  mobileTitleContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "100%",

    [ScreenSizes.xlAndAbove]: {
      flexDirection: "row",
      alignItems: "center",
    },
  },
  mobileCardPrimaryTitle: {
    ...getFontStyles("cardPrimaryTitle", "mobile"),
    marginBottom: ".375rem",
  },
  mobileCardPrimaryTitleSmaller: {
    ...getFontStyles("cardPrimaryTitleSmaller", "mobile"),
    marginBottom: ".375rem",
    fontSize: ".75rem",
  },
  mobileCardDescription: {
    ...getFontStyles("cardDescription", "mobile"),
  },
  mobileCardTopRow: {
    display: "flex",
    flexDirection: "row",
    padding: 0,
    paddingBottom: cardSizes.mobileStandardPadding,
  },
  mobileCardTopRowNoEntity: {
    padding: "0.75rem 1rem 0.5rem",
  },
  mobileCardEntityInfo: {
    display: "flex",
    flexDirection: "column",
    padding: 0,
    paddingLeft: cardSizes.mobilePaddingBetweenThumbnailAndInfo,
    paddingRight: cardSizes.mobileButtonsFromRightSide,
    position: "relative",
    width: "100%",
    overflow: "hidden",
  },
  mobileCardEntityImage: {
    height: cardImageSizes.mobileCardEntity,
    minWidth: cardImageSizes.mobileCardEntity,
    width: cardImageSizes.mobileCardEntity,
    borderRadius: "0.0625em",
    overflow: "hidden",

    [ScreenSizes.lgAndAbove]: {
      height: "5rem",
      minWidth: "5rem",
      width: "5rem",
    },
  },
};

export const raisedCardStyles = {
  backgroundColor: cardTokens.color.background.default,
  boxShadow: cardTokens.boxShadow, // "0 2px 4px rgb(154 160 185 / 7%), 0 6px 11px rgb(166 173 201 / 20%)",
  border: `1px solid ${cardTokens.color.border.default}`,
  padding: cardSizes.raisedCardPadding,
  borderRadius: cardTokens.borderRadius,
};

export const noRaisedCardStyles = {
  padding: 0,
  backgroundColor: "transparent",
  borderRadius: 0,
  boxShadow: "none",
  border: "none",
};

export const flatCardStyles = {
  ...mobileFlatCardStyles,
  padding: cardSizes.mobileFlatCardPadding,
};

const desktopCardStyles = {
  flatCard: {
    ...flatCardStyles,
  },
  raisedCard: {
    ...raisedCardStyles,
  },
  raisedCardSubtle: {
    ...raisedCardStyles,
    // boxShadow: "0 2px 5px rgb(154 160 185 / 4%), 0 5px 6px rgb(166 173 201 / 12%)",
  },
  noRaisedCard: {
    ...noRaisedCardStyles,
  },
  desktopActivityCard: {
    [ScreenSizes.lgAndAbove]: {
      backgroundColor: "none",
      marginBottom: "2rem",
      boxShadow: "none",
      padding: 0,
    },
  },
  desktopCard: {
    width: "100%",
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",
    ...raisedCardStyles,
  },
  desktopTitleContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  desktopCardPrimaryTitle: {
    ...getFontStyles("cardPrimaryTitle"),
    marginBottom: ".375rem",
  },
  desktopCardPrimaryTitleSmaller: {
    ...getFontStyles("cardPrimaryTitleSmaller"),
  },
  activityListItemTitle: {
    ...getFontStyles("activityListItemTitle"),
    lineHeight: 1.2,
  },
  entityLinkContainer: {
    ...getFontStyles("activityListItemTitle"),
    ...gStyles.noEllipsis,
    lineHeight: 1.2,
    display: "flex", // This fixes weird height differences in spans
    flexDirection: "row",
    marginBottom: "0.25rem",
  },
  desktopCardEntityInfo: {
    display: "flex",
    flexDirection: "column",
    padding: 0,
    paddingLeft: cardSizes.paddingBetweenThumbnailAndInfo,
    paddingRight: cardSizes.buttonsFromRightSide,
    position: "relative",
    width: "100%",
    overflow: "hidden",
  },
  desktopCardEntityImage: {
    display: "block",
    background: "rgba(0,0,0,0.04)",
    height: cardImageSizes.cardEntity,
    minWidth: cardImageSizes.cardEntity,
    width: cardImageSizes.cardEntity,
    borderRadius: 4,
    overflow: "hidden",
  },
  desktopCompactCardEntityImage: {
    display: "block",
    background: "rgba(0,0,0,0.04)",
    height: cardImageSizes.compactCardEntity,
    minWidth: cardImageSizes.compactCardEntity,
    width: cardImageSizes.compactCardEntity,
  },
  desktopCardDescription: {
    ...getFontStyles("cardDescription"),
  },
  desktopCardDescriptionLink: {
    ...getFontStyles("cardDescriptionLink"),
  },
  desktopShareContainer: {
    paddingLeft: "1rem",
    marginLeft: "auto",
  },
  mobileShareContainer: {
    paddingLeft: ".8rem",
    marginLeft: "auto",
  },
};

const cardStyles = {
  ...mobileCardStyles,
  ...desktopCardStyles,
  entityList: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",

    [ScreenSizes.mdAndAbove]: {
      gap: "1.5rem",
    },
  },
  cardContainer: {
    borderBottom: "1px solid var(--color-neutral-l2)",
    paddingLeft: cardSizes.mobileStandardPadding,
    paddingRight: cardSizes.mobileStandardPadding,

    ":last-child": {
      borderBottom: "none",
    },

    [ScreenSizes.lgAndAbove]: {
      borderBottom: "none",
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  feedBackground: {
    backgroundColor: "#eaedef",
  },
  cardActions: {
    display: "flex",
    flexDirection: "row",
    marginLeft: "auto",
  },
  cardNoPanel: {
    ...noRaisedCardStyles,
    [ScreenSizes.lgAndAbove]: {
      ...noRaisedCardStyles,
    },
  },
  activityList: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: "1rem",
  },
  episodePodcastName: {
    ...getFontStyles("episodePodcastName"),
  },
  episodePodcastNameSmall: {
    ...getFontStyles("episodePodcastNameSmall"),
  },
  episodePodcastNamePosition: {
    marginTop: 0,
    marginBottom: ".375rem",
  },
  cardUsername: {
    ...getFontStyles("cardUsername"),
    ...gStyles.textEllipsis,
  },
  verbText: {
    ...getFontStyles("verbText"),
  },
  topRow: {
    display: "flex",
    flexDirection: "row",
    paddingBottom: cardSizes.mobilePaddingBetweenDividerAndButtons,
    marginBottom: cardSizes.mobilePaddingBetweenDividerAndButtons,
    borderBottom: "1px solid var(--color-neutral-d95)",

    [ScreenSizes.lgAndAbove]: {
      paddingBottom: cardSizes.paddingBetweenDividerAndButtons,
      marginBottom: cardSizes.paddingBetweenDividerAndButtons,
      borderBottom: "1px solid var(--color-neutral-d95)",
    },
  },
  paddedTopRow: {
    display: "flex",
    flexDirection: "row",
    padding: cardSizes.mobileStandardPadding,
    paddingRight: cardSizes.mobileButtonsFromRightSide,
    [ScreenSizes.lgAndAbove]: {
      padding: cardSizes.standardPadding,
      paddingRight: cardSizes.buttonsFromRightSide,
    },
  },
  bottomRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: 0,
    paddingTop: cardSizes.mobileButtonsFromTopSide,
    position: "relative",
    minHeight: cardSizes.bottomRowIconSize,

    [ScreenSizes.lgAndAbove]: {
      minHeight: cardSizes.bottomRowIconSize,
      padding: 0,
      paddingTop: cardSizes.buttonsFromTopSide,
      paddingLeft: cardSizes.buttonsFromLeftSide,
      paddingRight: cardSizes.buttonsFromRightSide,
    },
  },
  bottomRowInsidePanel: {},
  bottomRowCategories: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginRight: ".6rem",
  },
  bottomRowGroupTitle: {
    ...gStyles.avalonBold,
    color: "var(--color-neutral-d6)",
    fontSize: 11,
    marginRight: ".875rem",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
};

export const noBorderCardStyles = {
  card: {
    border: "none",
  },
  topRow: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
  },
  description: {
    paddingLeft: 0,
    paddingRight: 0,
    marginBottom: 0,
  },
  bottomRow: {
    padding: 0,
    paddingLeft: 0,
    paddingRight: 0,
    border: "none",
  },
  entityInfo: {
    paddingRight: 0,
  },
};

export default cardStyles;
