import { faSortDown } from "@fortawesome/free-solid-svg-icons/faSortDown";
import { faSortUp } from "@fortawesome/free-solid-svg-icons/faSortUp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import PropTypes from "prop-types";
import { useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";

import navMenuStyles from "../navMenuStyles";
import NavMenuDropdownItemLabel from "./NavMenuDropdownItemLabel";

import accessibleClickProps from "utils/misc/accessibleClickProps";
import { dropdownEventIsFieldTrigger } from "utils/misc/eventIsFieldTrigger";

import useHover from "hooks/useHover";
import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import colours from "styles/colours";
import ScreenSizes, { createBreakpoint } from "styles/ScreenSizes";

const baseStyles = {
  ...navMenuStyles,
  navItem: {
    border: "none",
    paddingRight: 0,
    [ScreenSizes.lgAndAbove]: {
      transition: "none",
      paddingRight: "0.56rem",
      paddingLeft: "0.875rem",
      borderRadius: "10rem",
    },
    [createBreakpoint({ max: 1400 })]: {
      maxHeight: "2.375rem",
      alignSelf: "center",
    },
    ...navMenuStyles.navItem,
  },
  navItemDropdownArrow: {
    fontSize: ".875rem",
    marginLeft: ".5rem",
    alignSelf: "start",
    display: "none",
    paddingTop: "0.32rem",

    [ScreenSizes.lgAndAbove]: {
      display: "initial",
      opacity: 0,
    },
    [createBreakpoint({ min: 1400 })]: {
      paddingTop: "0.2rem",
    },
  },
  navItemButton: {
    display: "flex",
  },
  hoveredNavItem: {
    [ScreenSizes.lgAndAbove]: {
      opacity: 1,
    },
  },
  hoveredNavItemButton: {
    [ScreenSizes.lgAndAbove]: {
      background: "var(--color-secondary-d4)",
    },
  },
  hoveredNavItemButtonLight: {
    [ScreenSizes.lgAndAbove]: {
      background: colours.neutral,
    },
  },
  wBadge: {
    position: "relative",
  },
  whiteNavBarLabel: {
    color: colours.black,
  },
  navItemDropdownArrowOpen: {
    paddingTop: ".4rem",
    [createBreakpoint({ min: 1400 })]: {
      paddingTop: ".5rem",
    },
  },
  labelContainer: {
    display: "flex",
  },
};

const NavMenuDropdownToggleButton = (props) => {
  const {
    item,
    downshiftProps: { ref, getToggleButtonProps, isOpen, toggleMenu },
    switchToWhiteStyles,
  } = props;
  const { styles } = useStyles(baseStyles, props);

  const [hoverRef, isHovered] = useHover();

  const { isWindowSizeOrLess } = useWindowSize();
  const mobile = isWindowSizeOrLess("medium");

  const location = useLocation();

  const { badge, menuRoutes } = item;

  const handleStopPropagation = useCallback((e) => e.stopPropagation(), []);

  const isActive = useMemo(() => {
    if (menuRoutes) {
      return menuRoutes.some((route) => location.pathname.includes(route));
    }

    return false;
  }, [location, menuRoutes]);

  const buttonProps = getToggleButtonProps();
  const accessibleProps = accessibleClickProps(
    (e) => {
      if (e.keyCode === 13) {
        toggleMenu();
      }

      buttonProps.onKeyDown(e);
    },
    { triggerValidator: dropdownEventIsFieldTrigger }
  );

  return (
    <button
      className={css(
        badge && styles.wBadge,
        styles.navItemOuter,
        styles.navItem,
        isActive && styles.navItemActive,
        switchToWhiteStyles && styles.whiteNavBarLabel,
        ((isHovered && !switchToWhiteStyles) ||
          (isOpen && !switchToWhiteStyles)) &&
          styles.hoveredNavItemButton,
        ((isHovered && switchToWhiteStyles) ||
          (isOpen && switchToWhiteStyles)) &&
          styles.hoveredNavItemButtonLight
      )}
      {...buttonProps}
      onKeyDown={accessibleProps.onKeyDown}
      onMouseDown={handleStopPropagation}
      onMouseUp={handleStopPropagation}
      onTouchStart={handleStopPropagation}
      onTouchEnd={handleStopPropagation}
      ref={hoverRef}
    >
      <NavMenuDropdownItemLabel item={item} ref={mobile ? ref : null} />
      <span
        ref={mobile ? undefined : ref}
        className={css(
          styles.navItemDropdownArrow,
          (isHovered || isOpen) && styles.hoveredNavItem,
          isOpen && styles.navItemDropdownArrowOpen
        )}
      >
        <FontAwesomeIcon icon={isOpen ? faSortUp : faSortDown} />
      </span>
      {badge && badge.text ? (
        <span className={css(styles.navTitleBadge)}>{badge.text}</span>
      ) : null}
    </button>
  );
};

NavMenuDropdownToggleButton.propTypes = {
  downshiftProps: PropTypes.object.isRequired,
  item: PropTypes.shape({
    badge: PropTypes.object,
    menuRoutes: PropTypes.array,
  }).isRequired,
  switchToWhiteStyles: PropTypes.bool,
};

export default NavMenuDropdownToggleButton;
