import PropTypes from "prop-types";
import { memo } from "react";

import SlidingContainer from "components/Common/FlexMenu/SlidingContainer";

import modalActionCreators from "actions/modals";

import useActionCreators from "hooks/useActionCreators";

const BaseModal = (props) => {
  const {
    hideOverlay,
    allowClose,
    title,
    onRequestClose,
    closeModal,
    slideProps,
    contentClassName,
    onClose,
  } = props;

  const modalActions = useActionCreators(modalActionCreators);

  return (
    <SlidingContainer
      showOverlay={!hideOverlay}
      hideOverlayClose
      title={title}
      contentClassName={contentClassName}
      allowClose={allowClose}
      open
      styles={slideProps?.slidingStyles}
      {...props}
      {...slideProps}
      onClose={onRequestClose || closeModal || modalActions.closeModal}
      extraOnClose={onClose}
    />
  );
};

BaseModal.propTypes = {
  closeModal: PropTypes.func,
  onRequestClose: PropTypes.func,
  title: PropTypes.string,
  closeOnBackButton: PropTypes.bool,
  children: PropTypes.node,
  hideOverlay: PropTypes.bool,
  allowClose: PropTypes.bool,
  slideProps: PropTypes.object,
  renderContent: PropTypes.func,
  contentClassName: PropTypes.object,
  onClose: PropTypes.func,
};

BaseModal.defaultProps = {
  closeModal: null,
  onRequestClose: null,
  closeOnBackButton: true,
  title: "Dialog",
  children: null,
  hideOverlay: false,
  allowClose: true,
  renderContent: null,
  slideProps: {},
  contentClassName: null,
  onClose: null,
};

export default memo(BaseModal);
