import { css } from "aphrodite";
import PropTypes from "prop-types";
import {
  memo,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";

import generateTransition from "utils/generateTransition";
import { isBeingViewedOnMobile } from "utils/size/isPhone";

import usePopper from "hooks/usePopper";
import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";

const baseStyles = {
  tooltip: {
    ...gStyles.fontRegular,
    color: colours.white,
    backgroundColor: "#444444",
    padding: ".5rem .75rem",
    boxShadow: "0px 5px 16px 0px rgba(0, 0, 0, 0.07)",
    borderRadius: 8,
    fontSize: "11px",
    zIndex: 800,
    opacity: 0,
    transition: generateTransition({ target: "opacity", speed: "300ms" }),
  },
  tooltipVisible: {
    opacity: 1,
  },
  tooltopArrowTop: {
    marginBottom: "6px",
  },
  tooltopArrowBottom: {
    marginTop: "6px",
  },
  tooltipLight: {
    backgroundColor: colours.white,
    color: colours.black,
  },
  contentDecor: {
    zIndex: -1,
    position: "absolute",
    backgroundColor: "#444444",
    width: "12px",
    height: "12px",
    transform: "rotate(45deg)",
  },
  contentDecorLight: {
    backgroundColor: colours.white,
  },
  arrowBottom: {
    top: "-6px",
    paddingTop: ".1rem",
    boxShadow: "-2px -2px 3px 0 rgba(0, 0, 0, 0.1)",
  },
  arrowTop: {
    bottom: "-6px",
    paddingBottom: ".1rem",
    boxShadow: "2px 2px 3px 0 rgba(0, 0, 0, 0.1)",
  },
};

const BasicTooltip = (props) => {
  const {
    render,
    children,
    renderContent,
    renderTooltip,
    hideOnTouchDevice,
    placement,
    enterTimeout,
    leaveTimeout,
    zIndex,
    showOnHover,
    forceShow,
    light,
    modifiers,
  } = props;

  const liveStyles = useMemo(
    () => ({
      tooltip: {
        zIndex,
      },
    }),
    [zIndex]
  );

  const { styles } = useStyles([baseStyles, liveStyles], props);

  const {
    reference,
    popper,
    popperStyles,
    arrow,
    arrowStyles,
    placement: popperPlacement,
  } = usePopper({
    placement,
    ...modifiers,
  });

  const renderReference = render || children;
  const [hover, setHover] = useState(false);
  const [visible, setVisible] = useState(false);

  const mouseTimeout = useRef(null);
  const mouseTimeout2 = useRef(null);

  const startDisplaying = useCallback(() => {
    setHover(true);
    clearInterval(mouseTimeout.current);
    clearInterval(mouseTimeout2.current);
    mouseTimeout.current = setTimeout(() => {
      setVisible(true);
    }, enterTimeout);
  }, [enterTimeout, setVisible, setHover]);

  const stopDisplaying = useCallback(() => {
    clearInterval(mouseTimeout.current);
    clearInterval(mouseTimeout2.current);
    mouseTimeout.current = setTimeout(() => {
      setVisible(false);
    }, leaveTimeout);
    mouseTimeout2.current = setTimeout(() => {
      setHover(false);
    }, leaveTimeout);
  }, [leaveTimeout, setVisible]);

  const onMouseEnter = useCallback(() => {
    if (!showOnHover) {
      return null;
    }
    startDisplaying();
  }, [startDisplaying, showOnHover]);

  const onMouseLeave = useCallback(() => {
    if (!showOnHover) {
      return null;
    }
    stopDisplaying();
  }, [showOnHover, stopDisplaying]);

  useEffect(() => {
    if (forceShow) {
      startDisplaying();
    } else {
      stopDisplaying();
    }
  }, [forceShow, startDisplaying, stopDisplaying]);

  const onFocus = useCallback(() => {
    if (!showOnHover) {
      return null;
    }
    setVisible(true);
    setHover(true);
  }, [showOnHover]);

  const onBlur = useCallback(() => {
    if (!showOnHover) {
      return null;
    }
    setVisible(false);
    setHover(false);
  }, [showOnHover]);

  const renderTooltipContainer = () => {
    const isTopArrow =
      popperPlacement && popperPlacement.substr(0, 3) === "top";

    return (
      <div
        className={css(
          styles.tooltip,
          light && styles.tooltipLight,
          isTopArrow ? styles.tooltopArrowTop : styles.tooltopArrowBottom,
          visible && styles.tooltipVisible
        )}
        ref={popper}
        style={popperStyles}
        data-placement={popperPlacement}
      >
        <div
          className={css(
            styles.contentDecor,
            light && styles.contentDecorLight,
            popperPlacement && isTopArrow && styles.arrowTop,
            popperPlacement && !isTopArrow && styles.arrowBottom
          )}
          ref={arrow}
          style={arrowStyles}
        />
        {renderTooltip()}
      </div>
    );
  };

  return (
    <Fragment>
      {renderReference({
        ref: reference,
        ...(showOnHover && {
          onMouseEnter,
          onMouseLeave,
          onFocus,
          onBlur,
        }),
      })}
      {hover &&
        (!hideOnTouchDevice || !isBeingViewedOnMobile()) &&
        document &&
        createPortal(
          renderTooltip
            ? renderTooltipContainer()
            : renderContent({
                ref: popper,
                style: { ...popperStyles, opacity: visible ? 1 : 0 },
                "data-placement": popperPlacement,
                arrowProps: {
                  ref: arrow,
                  style: arrowStyles,
                },
              }),
          document && document.querySelector("#app-base")
        )}
    </Fragment>
  );
};

BasicTooltip.propTypes = {
  renderContent: PropTypes.func,
  renderTooltip: PropTypes.func,
  children: PropTypes.func,
  render: PropTypes.func,
  hideOnTouchDevice: PropTypes.bool,
  placement: PropTypes.oneOf([
    "top",
    "top-start",
    "top-end",
    "bottom",
    "bottom-start",
    "bottom-end",
    "left",
    "left-start",
    "left-end",
    "right",
    "right-start",
    "right-end",
    "auto",
  ]),
  enterTimeout: PropTypes.number,
  leaveTimeout: PropTypes.number,
  zIndex: PropTypes.number,
  showOnHover: PropTypes.bool,
  forceShow: PropTypes.bool,
  light: PropTypes.bool,
  modifiers: PropTypes.object,
};

BasicTooltip.defaultProps = {
  renderContent: null,
  renderTooltip: null,
  children: null,
  render: null,
  hideOnTouchDevice: false,
  placement: "top",
  enterTimeout: 0,
  leaveTimeout: 200,
  zIndex: 10,
  showOnHover: true,
  forceShow: false,
  light: false,
  modifiers: {},
};

export default memo(BasicTooltip);
