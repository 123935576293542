import { commonModuleGroup, podcastModuleGroup } from "modules/groups";
import {
  CLAIMING_MODULE,
  CREATOR_EDIT_MODULE,
  CREATOR_VIEW_MODULE,
  MERCH_MODULE,
  USERLIST_COMMON_MODULE,
  USER_PROFILE_MODULE,
  INSIGHTS_PDF_MODULE,
} from "modules/moduleIds";
import { Switch } from "react-router-dom";

import TopLevelRoute from "pages/Common/TopLevelRoute";
import SingleReviewView from "pages/PodcastView/SingleReviewView/async";

import Podcasts from "./Discover/Podcasts/async";
import EpisodeView from "./EpisodeView/async";
import PodcastView from "./PodcastView/async";
import PodcastAboutViewContainerAsync from "./PodcastView/PodcastAboutViewContainerAsync";
import PodcastEpisodesAsync from "./PodcastView/PodcastEpisodesAsync";
import PodcastViewSkeleton from "./PodcastView/PodcastViewSkeleton";

import ScreenSizes, { createBreakpoint } from "styles/ScreenSizes";

const PAGE_HEIGHT = 4000;
const PAGE_ABOUT_HEIGHT = 4000;

const DISCOVER_PODCAST_LOADING_STYLES = {
  height: {
    height: "500rem",
    maxHeight: "500rem",
    minHeight: 0,

    [ScreenSizes.lgAndAbove]: {
      height: "337rem",
      maxHeight: "337rem",
    },
  },
};

const EPISODE_VIEW_LOADING_STYLES = {
  height: {
    height: "318rem",
    maxHeight: "318rem",
    minHeight: 0,

    [ScreenSizes.lgAndAbove]: {
      height: "200rem",
      maxHeight: "200rem",
    },
  },
};

const homepageStyles = {
  topHeaderPlaceholder: {
    [ScreenSizes.lgAndAbove]: {
      minHeight: 0,
    },

    [createBreakpoint({ min: 1400 })]: {
      minHeight: 0,
    },
  },
};

const headerNavWrapperStyles = {
  container: {
    [ScreenSizes.lgAndAbove]: {
      width: "100%",
      background: "none",
      boxShadow: "none",
      zIndex: 15,
      position: "absolute",
    },
  },
  secondNav: {
    [ScreenSizes.lgOnly]: {
      position: "relative",
      display: "block",
    },
    [createBreakpoint({ min: 1400 })]: {
      display: "none",
    },
  },
};

const episodesRecentLoadingStyles = {
  height: {
    height: "383rem",
    maxHeight: "383rem",
    minHeight: 0,

    [ScreenSizes.lgAndAbove]: {
      height: "159rem",
      maxHeight: "159rem",
    },
  },
};

const renderPodcastView = (
  path = "",
  exact = false,
  loadingStyles,
  topLevelRouteProps,
  isInsightPage
) => (
  <TopLevelRoute
    path={`/podcast(s?)/:id(.*[0-9]+)${path}`}
    {...topLevelRouteProps}
    transparent={false}
    styles={homepageStyles}
    headerNavWrapperStyles={headerNavWrapperStyles}
    exact={exact}
    modules={[
      ...commonModuleGroup,
      ...podcastModuleGroup,
      CREATOR_VIEW_MODULE,
      USER_PROFILE_MODULE,
      USERLIST_COMMON_MODULE,
      MERCH_MODULE,
      CLAIMING_MODULE,
      INSIGHTS_PDF_MODULE,
    ]}
    height={PAGE_HEIGHT}
    loadingStyles={loadingStyles}
    asyncComponent={<PodcastViewSkeleton />}
  >
    <PodcastView isInsightPage={isInsightPage} />
  </TopLevelRoute>
);

function PodcastRoutes({ isMedium, topLevelRouteProps }) {
  return (
    <Switch>
      <TopLevelRoute
        path={`/podcast(s?)/:id(.*[0-9]+)/episodes/:sort(recent)`}
        {...topLevelRouteProps}
        transparent={false}
        styles={homepageStyles}
        headerNavWrapperStyles={headerNavWrapperStyles}
        exact={true}
        modules={[
          ...commonModuleGroup,
          ...podcastModuleGroup,
          CREATOR_VIEW_MODULE,
          USER_PROFILE_MODULE,
          USERLIST_COMMON_MODULE,
          MERCH_MODULE,
          CLAIMING_MODULE,
          INSIGHTS_PDF_MODULE,
        ]}
        height={PAGE_HEIGHT}
        loadingStyles={episodesRecentLoadingStyles}
      >
        <PodcastEpisodesAsync />
      </TopLevelRoute>

      {renderPodcastView(
        "/episodes/:sort(ranking|top)",
        true,
        episodesRecentLoadingStyles,
        topLevelRouteProps
      )}

      {isMedium && (
        <TopLevelRoute
          path="/podcast(s?)/:id/episode(s?)/:episode_id/reviews/:review_id(.*[0-9]+)"
          {...topLevelRouteProps}
          transparent={false}
          modules={[...commonModuleGroup, ...podcastModuleGroup]}
        >
          <SingleReviewView />
        </TopLevelRoute>
      )}

      <TopLevelRoute
        path="/podcast(s?)/:id/episode(s?)/:episode_id(.*[0-9]+)"
        {...topLevelRouteProps}
        transparent={false}
        modules={[
          ...commonModuleGroup,
          ...podcastModuleGroup,
          USER_PROFILE_MODULE,
          USERLIST_COMMON_MODULE,
          CREATOR_VIEW_MODULE,
          CREATOR_EDIT_MODULE,
          INSIGHTS_PDF_MODULE,
        ]}
        height={PAGE_HEIGHT}
        loadingStyles={EPISODE_VIEW_LOADING_STYLES}
      >
        <EpisodeView />
      </TopLevelRoute>

      {isMedium && (
        <TopLevelRoute
          path="/podcast(s?)/:id/reviews/:review_id(.*[0-9]+)"
          {...topLevelRouteProps}
          transparent={false}
          modules={[
            ...commonModuleGroup,
            ...podcastModuleGroup,
            INSIGHTS_PDF_MODULE,
          ]}
        >
          <SingleReviewView />
        </TopLevelRoute>
      )}

      {renderPodcastView(
        "/insights",
        true,
        undefined,
        topLevelRouteProps,
        true
      )}

      <TopLevelRoute
        path={"/podcast(s?)/:id(.*[0-9]+)"}
        {...topLevelRouteProps}
        transparent={false}
        styles={homepageStyles}
        headerNavWrapperStyles={headerNavWrapperStyles}
        modules={[
          ...commonModuleGroup,
          ...podcastModuleGroup,
          CREATOR_VIEW_MODULE,
          USER_PROFILE_MODULE,
          USERLIST_COMMON_MODULE,
          MERCH_MODULE,
          CLAIMING_MODULE,
          INSIGHTS_PDF_MODULE,
        ]}
        height={PAGE_ABOUT_HEIGHT}
        asyncComponent={<PodcastViewSkeleton />}
      >
        <PodcastAboutViewContainerAsync />
      </TopLevelRoute>

      <TopLevelRoute
        path="/podcast(s?)/:sort(ranking|recentw|trending|power|top|new)"
        exact
        transparent={false}
        {...topLevelRouteProps}
        modules={[
          ...commonModuleGroup,
          ...podcastModuleGroup,
          CREATOR_VIEW_MODULE,
          USERLIST_COMMON_MODULE,
          INSIGHTS_PDF_MODULE,
        ]}
        height={5400}
        loadingStyles={DISCOVER_PODCAST_LOADING_STYLES}
      >
        <Podcasts />
      </TopLevelRoute>
      <TopLevelRoute
        path="/podcast(s?)"
        exact
        {...topLevelRouteProps}
        transparent={false}
        modules={[
          ...commonModuleGroup,
          ...podcastModuleGroup,
          CREATOR_VIEW_MODULE,
          USERLIST_COMMON_MODULE,
        ]}
        height={5400}
        loadingStyles={DISCOVER_PODCAST_LOADING_STYLES}
      >
        <Podcasts />
      </TopLevelRoute>
    </Switch>
  );
}

export default PodcastRoutes;
