import { faCaretDown } from "@fortawesome/free-solid-svg-icons/faCaretDown";
import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Map, List } from "immutable";
import PropTypes from "prop-types";
import { memo, useCallback, useMemo, useState } from "react";

import BasicField from "components/Common/FormElements/BasicField";
import { baseStyles as selectStyles } from "components/Lists/SelectStyles";
import { hasSubOption } from "pages/Discover/Charts/Charts";

import { makeSelectSpecificSubCategoryMemoized } from "selectors/charts";
import generateTransition from "utils/generateTransition";

import useReduxState from "hooks/useReduxState";
import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import fieldStyles from "styles/FieldStyles";
import gStyles from "styles/GenericStyles";
import getHoverQuery from "styles/getHoverQuery";

export const sideIcon = {
  maxWidth: "1em",
  display: "block",
  flex: "0 0 auto",
  alignSelf: "center",
  justifySelf: "center",
  height: "auto",
  transition: generateTransition({
    targets: ["opacity", "transform"],
    speed: "400ms",
  }),
};

const baseStyles = {
  outer: {
    width: "100%",
    position: "relative",
    margin: 0,
    fontSize: "inherit",
  },
  input: {
    ...gStyles.headerFilterButton,
    height: "2rem",
  },
  content: {
    ...gStyles.headerFilterButtonContent,
    flex: "1 1 100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0.7em 1rem",
  },
  icon: {
    ...gStyles.headerFilterButtonIcon,
    fontSize: "0.875rem",
    display: "block",
    maxWidth: "1.25em",
    textAlign: "center",
    flex: "0 0 auto",
    marginRight: "0.5rem",
  },
  text: {
    ...gStyles.headerFilterButtonLabel,
    flex: "1 1 auto",
    paddingRight: "0.5em",
    fontSize: ".875rem",
  },
  sideButtons: {
    display: "flex",
    flex: "0 0 auto",
    flexDirection: "row",
    ...selectStyles.sideButtons,
    height: "auto",
  },
  sideIcon: {
    ...sideIcon,
  },
  toggleIcon: {
    ...sideIcon,
    transformOrigin: "50%",
  },
  options: {
    ...gStyles.softShadow,
    position: "absolute",
    zIndex: 50,
    width: "100%",
    minWidth: "12em",
    background: "#fff",
    borderRadius: 14,
    overflow: "hidden",
  },

  openToggleIcon: {
    opacity: 0.3,
    transform: "scaleY(-1)",
  },
  openToggle: {
    opacity: 1,
    color: colours.black,
    transform: "scaleY(-1)",
  },

  sideButton: {
    ...gStyles.noButton,
    ...selectStyles.sideButtons,
    height: "auto",
    display: "flex",
    flex: "0 0 auto",
    flexDirection: "column",
    justifySelf: "stretch",
    alignItems: "center",
    justifyContent: "center",
    width: "max-content",
    marginRight: "0.5rem",
    textAlign: "center",
  },
  maxContent: {
    width: "100%",
  },
  selectedItemButton: {
    ...gStyles.headerFilterButtonSelected,
  },

  expandContainer: {
    width: "100%",
  },
  title: {
    ...gStyles.fontRegular,
    fontSize: "0.625rem",
    margin: 0,
  },
  fieldWrapper: {
    position: "relative",
  },
  searchIcon: {
    position: "absolute",
    top: "50%",
    transform: "translate(0, -50%)",
    fontSize: "0.563rem",
    left: "0.625rem",
  },
  iconWrapper: {
    position: "absolute",
    top: "50%",
    right: 0,
    transform: "translate(0, -50%)",
    fontSize: ".8rem",
    border: "none",
    background: "transparent",
    padding: "1rem",
    cursor: "pointer",
  },
  titleContainer: {
    display: "grid",
    gridTemplateColumns: "1fr max-content",
    gridGap: "0.5rem",
    alignItems: "center",
  },
  hidden: {
    height: "max-content",
    borderRadius: "0.5rem",
    padding: 8,
    background: "none",
    border: `0.5px solid ${colours.borderGrey}`,
    color: colours.black,

    ":focus": {
      color: colours.black,
      border: `0.5px solid ${colours.borderGrey}`,
    },
  },
  chevron: {
    outline: "none",
    border: "none",
    padding: 0,

    ":hover": {
      cursor: "pointer",
    },
  },
};

const fieldStyling = {
  inputField: {
    ...fieldStyles.inputField,
    fontSize: "0.625rem",
    height: "1.688rem",
    margin: "0.6rem 0",
    padding: "0.5em 2.5em 0.5em",
    ...getHoverQuery({
      boxShadow: "none",
    }),
    ":focus": {
      boxShadow: "none",
    },
    "::placeholder": {
      ...gStyles.fontRegularItalic,
      color: colours.newDarkGrey,
    },
  },
};

function SelectSearchDropdown(props) {
  const { styles, css } = useStyles(baseStyles, props);
  const {
    selected,
    ariaLabel,
    defaultSelectedId,
    label,
    filter,
    RenderList,
    handleClick,
    useInteracted,
    interacted,
  } = props;

  const options = filter.get("options");

  const [filteredOptions, setFilteredOptions] = useState(options);
  const [searchTerm, setSearchTerm] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [isOpen, setOpen] = useState(false);

  const childSelectedCat = hasSubOption(selected);
  const selectSpecificSubCategory = makeSelectSpecificSubCategoryMemoized();
  const { selectedOption, selectedSubOption } = useMemo(() => {
    const selectedOption =
      options &&
      options.find((s) => {
        const subOptions = s?.get("subOptions");

        let selectedChild = null;
        subOptions?.forEach((element) => {
          if (
            childSelectedCat?.toLowerCase() ===
              element?.get("value")?.toLowerCase() ||
            childSelectedCat?.toLowerCase() ===
              element?.get("id")?.toLowerCase()
          ) {
            selectedChild = childSelectedCat;
          }
        });

        if (subOptions?.size > 0 && selectedChild) {
          return s !== null && selectedChild !== null;
        }

        return (
          s !== null &&
          (s?.get("id")?.toLowerCase() === selected?.toLowerCase() ||
            s?.get("value")?.toLowerCase() === selected?.toLowerCase())
        );
      });

    const subOptions = selectedOption?.get("subOptions");
    let selectedSubOption = null;
    subOptions?.forEach((element) => {
      if (
        childSelectedCat?.toLowerCase() ===
          element?.get("value")?.toLowerCase() ||
        childSelectedCat?.toLowerCase() === element?.get("id")?.toLowerCase()
      ) {
        selectedSubOption = childSelectedCat;
      }
    });

    return {
      selectedOption,
      selectedSubOption,
    };
  }, [childSelectedCat, options, selected]);

  const toggleButton = useCallback(() => {
    setOpen(!isOpen);
    if (isOpen && searchTerm !== "") {
      handleClear();
    }
  }, [isOpen, searchTerm]);
  const handleSearch = (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);

    if (searchTerm?.trim() !== "") {
      const filtered = options
        ?.map((option) => {
          const subOptions = option.get("subOptions");

          let newParent = Map();
          const newChild = [];

          let searchResultFoundInSubOption = false;
          subOptions?.forEach((sub) => {
            if (
              sub.get("label").toLowerCase().includes(searchTerm.toLowerCase())
            ) {
              newChild.push(sub);
              if (searchTerm?.trim() !== "") {
                searchResultFoundInSubOption = true;
              }
            }
          });

          const parentMatched = option
            .get("label")
            .toLowerCase()
            .includes(searchTerm.toLowerCase());

          if (parentMatched || searchResultFoundInSubOption) {
            newParent = option
              .set("subOptions", List(newChild))
              .set("resultInSubOption", searchResultFoundInSubOption)
              .set("resultInParent", parentMatched);
          }

          return newParent;
        })
        .filter((i) => i?.size !== 0);

      const sortedOptions = filtered.sort((a, b) => {
        const relevanceA = a
          .get("label")
          .toLowerCase()
          .indexOf(searchTerm.toLowerCase());
        const relevanceB = b
          .get("label")
          .toLowerCase()
          .indexOf(searchTerm.toLowerCase());
        return relevanceA - relevanceB;
      });

      setFilteredOptions(sortedOptions);
      setIsSearching(true);
    } else {
      setFilteredOptions(options);
      setIsSearching(false);
    }
  };
  const handleClear = () => {
    setSearchTerm("");
    setIsSearching(false);
  };

  const currentOptions = isSearching ? filteredOptions : options;
  const orderedFilters = List(currentOptions);
  const subOptionLabel = useReduxState(
    (state) => {
      let subOptionLabel = null;
      if (selectedSubOption) {
        const parentSelectedCat = selected?.split("/")[0];

        const selectedChildCategory = selectSpecificSubCategory(
          state,
          parentSelectedCat,
          selectedSubOption
        );

        subOptionLabel = selectedChildCategory?.label;
      }

      return subOptionLabel;
    },
    [selected]
  );

  const canApplyDefaultStyles =
    selectedOption &&
    defaultSelectedId !== selectedOption?.get("id") &&
    (!useInteracted || (useInteracted && interacted));

  return (
    <div
      data-id="layout-select"
      className={css(styles.outer)}
      aria-label={ariaLabel}
    >
      <button
        className={css(
          styles.input,
          styles.maxContent,
          canApplyDefaultStyles && styles.selectedItemButton,
          isOpen && styles.hidden
        )}
        onClick={isOpen ? null : toggleButton}
        aria-label={ariaLabel}
        data-testid={props.dataId}
      >
        {isOpen ? (
          <div className={css(styles.expandContainer)}>
            <div className={css(styles.titleContainer)} onClick={toggleButton}>
              {label && <h4 className={css(styles.title)}>{label}</h4>}
              <span className={css(styles.chevron)}>
                <FontAwesomeIcon
                  dataid="collapse"
                  icon={faCaretDown}
                  className={css(styles.openToggle)}
                />
              </span>
            </div>
            <div className={css(styles.fieldWrapper)}>
              <FontAwesomeIcon
                className={css(styles.searchIcon)}
                icon={faSearch}
                dataid="search"
              />
              <BasicField
                placeholder={`Search ${filter.get("title")}...`}
                value={searchTerm}
                onChange={handleSearch}
                styles={fieldStyling}
                data-testid="input"
                dataId="input"
              />
              {isSearching && (
                <button
                  onClick={handleClear}
                  data-testid="clear-btn"
                  className={css(styles.iconWrapper)}
                >
                  <FontAwesomeIcon
                    className={css(
                      styles.icon,
                      isSearching && styles.iconFocussed
                    )}
                    icon={faTimes}
                    dataid="searchClear"
                  />
                </button>
              )}
            </div>
            <RenderList
              data={orderedFilters}
              toggleButton={toggleButton}
              handleClick={handleClick}
              selected={selected}
              isSearching={isSearching}
            />
          </div>
        ) : (
          <>
            {selectedOption && selectedOption?.get("size") !== 0 ? (
              <span className={css(styles.content, styles.text)}>
                {subOptionLabel || selectedOption?.get("label")}
              </span>
            ) : (
              <span className={css(styles.content, styles.text)}>{label}</span>
            )}
            <div className={css(styles.sideButtons)}>
              <span className={css(styles.sideButton)}>
                <FontAwesomeIcon
                  dataid="dropDown"
                  icon={faCaretDown}
                  className={css(
                    isOpen ? styles.openToggleIcon : styles.toggleIcon
                  )}
                />
              </span>
            </div>
          </>
        )}
      </button>
    </div>
  );
}

SelectSearchDropdown.propTypes = {
  selected: PropTypes.string,
  filter: PropTypes.instanceOf(Map),
  topLabel: PropTypes.string,
  ariaLabel: PropTypes.string,
  label: PropTypes.string,
  styles: PropTypes.object,
  renderList: PropTypes.func,
  defaultSelectedId: PropTypes.string,
};

export default memo(SelectSearchDropdown);
